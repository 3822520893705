<template>
    <div class="page anim-fadeIn">
        <div id="depth">
            <!-- <async-img id="depth_upper" :image="images.depthUpper"/>
            <async-img id="depth_lower" :image="images.depthLower"/> -->
            <async-img id="depth_upper" :url="srcUpper"/>
            <img id="depth_connect" :src="require('@/assets/images/astro_v9/depth/connect.jpg')"/>
            <!-- <async-img id="depth_loop" :image="images.heroLoop"/> -->
            <async-img id="depth_loop" :url="require('@/assets/images/astro_v9/depth/loop.jpg')"/>
            <!-- <div id="depth_lower" :style="{
                'background-image': 'url('+ require('@/assets/images/astro_v9/depth/lower.jpg') +')'
            }"></div> -->
        </div>


        <div id="page_content">
            <div id="hero"
                 :style="'opacity:' + page1BackgroundOpacity +'; height: ' + heroHeight"
            >
                <h1 class="anim-fadeIn anim-delay-0 anim-duration-10">
                    <!-- The purest of water -->
                    {{$t('start.hero.header')}}
                </h1>
                <h2 class="anim-fadeIn anim-delay-6 anim-duration-10">
                    <!-- Bottled at source from ancient bedrock in northern scandinavia -->
                    {{$t('start.hero.subheader')}}
                </h2>
            </div>
            <div id="section_story" class="section" v-sp-inview="'-600px'">
                <h1 class="anim-fadeIn">{{$t('start.story.header')}}</h1>
                <p id="story_text">
                    <span>
                        {{$t('start.story.part1')}}
                    </span>
                    <br/>
                    <span>
                        {{$t('start.story.part2')}}
                    </span>
                    <br/>
                    <span>
                        {{$t('start.story.part3')}}
                    </span>
                    <br/>
                    <span>
                        {{$t('start.story.part4')}}
                    </span>
                    <br/>
                    <span>
                        {{$t('start.story.part5')}}
                    </span>
                </p>
            </div>
            <!-- <div id="section_timeline" class="section">
                <timeline/>
            </div> -->
            <div id="spacer"></div>
        </div>
    </div>
</template>

<script>
    // images
    import depthUpper from "@/assets/images/astro_v10/depth/upper_2.jpg";
    import depthUpperMobile from "@/assets/images/astro_v10/depth/mobile_upper_2.jpg";

    // components
    import AsyncImage from "../components/AsyncImage.vue";

    export default {
        components: {
            "async-img": AsyncImage,
        },
        props: ["images"],
        data() {
            return {
                heroHeight: "100px",
                page1BackgroundOpacity: 1,
            };
        },
        computed: {
            srcUpper() {
                if (this.$store.state.isMobile) {
                    return depthUpperMobile;
                }
                return depthUpper;
            },
        },
        mounted() {
            // remove cuz cookies
            // this.$gtag.pageview({ page_title: "track-Home" });
        },
        created() {
            window.addEventListener("scroll", this.handleScroll);
            // window.addEventListener("resize", this.handleResize);
            this.handleScroll();
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll);
            // window.removeEventListener("resize", this.handleResize);
        },
        methods: {
            handleScroll() {
                const { scrollY } = window;
                const scrollTarget = 200;
                const target = 1;
                const scrollOffset = 0;
                // Any code to be executed when the window is scrolled
                const percentage = (scrollY - scrollOffset) / scrollTarget;
                const value = Math.max(0, Math.min(target, (target) * percentage));
                // if (value !== lastOpacity) {
                //     $toolbarEffect.css("opacity", value);
                //     lastOpacity = value;
                // }

                if (value <= 1) {
                    this.page1BackgroundOpacity = 1 - value;
                } else {
                    this.page1BackgroundOpacity = 0;
                }
            },
            handleResize() {
                this.heroHeight = `${window.innerHeight - 64}px`;
            },
        },
    };
</script>


<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@hagbardpaulson/spatial-beta/dist/base/helpers.scss";

    // .page {
    //     position: absolute;
    //     top: 0;
    // }


    // wrapper for depth images
    #depth {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;

        @media only screen and (max-width: $medium-trigger-width) {
            &::after {
                display: flex;
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(darken($colorPurple, 10), .4) !important;
            }
        }

        &::after {
            display: flex;
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(darken($colorPurple, 10), .2);
        }

        #depth_upper {
            width: 100%;
            height: 100vh;
            vertical-align: middle;
            vertical-align: middle;
            object-fit: cover;
        }
        #depth_connect {
            width: 100%;
            // height: 100vh;
            vertical-align: middle;
            vertical-align: middle;
            object-fit: cover;
        }
        #depth_loop {
            width: 100%;
            height: calc(100% - 100vh);
            background-size: 100%;
            background-repeat: repeat-y;
            background-position: 50% 0;
        }
        // #depth_lower {
        //     width: 100%;
        //     height: 200px;
        //     background-size: 100%;
        //     background-position: center;
        // }
    }
    #depth_img {
        width: 100%;
    }

    #page_content {
        margin-top: 100vh;
        position: relative;
        max-width: 800px;
        width: 100%;

        // @media only screen and (max-width: $medium-trigger-width) {
        //     @include notch-padding-left(25px);
        //     @include notch-padding-right(25px);
        // }
    }

    #hero {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 10px;
        // height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;

        @media only screen and (max-width: $medium-trigger-width) {
            h1 {
                font-size: 2rem !important;
                text-align: center;
                letter-spacing: .1rem;
            }
            h2 {
                max-width: 400px !important;
                // font-size: 1.4rem !important;
            }
        }

        h1 {
            font-size: 3rem;
            text-align: center;
            letter-spacing: .1rem;
        }
        h2 {
            font-size: 1.2rem;
            font-weight: 400;
            text-align: center;
            font-family: "Montserrat", sans-serif !important;
            max-width: 510px;
            opacity: .9;
        }
    }

    .section {

    }
    #section_timeline {
        margin: 300px 0 20px 0;
    }
    #spacer {
        height: 100px;
    }

    #section_story {
        // display: grid !important;
        // grid-template-columns: 1fr 1fr;
        // grid-template-rows: auto 1fr;

        &.before-enter {
            span {
                opacity: 0;
                transform: translateX(-10px);
            }
        }
        &.enter {
            span {
                opacity: 1;
                transform: translateX(0px);
            }
        }

        h1 {
            // grid-column: span 2;
            // grid-row: 1;
            font-size: 1.8rem !important;
            color: white;
            margin: 0 auto 2rem auto !important;
        }
        #story_text {
            // grid-column: 1;
            // grid-row: 2;
            column-count: 2;
            column-gap: 20px;
            color: rgba(white, .8);
            font-size: .9rem !important;

            @media only screen and (max-width: $medium-trigger-width) {
                column-count: 1;
                column-gap: 20px;

                span {
                    opacity: 1 !important;
                }
            }


            br {
                margin: 0 0 1rem 0;
            }

            span {
                transition: 2s;

                &:nth-of-type() {
                    transition-delay: calc(.4s * 1) !important;
                }
                &:nth-of-type(2) {
                    transition-delay: calc(.4s * 2) !important;
                }
                &:nth-of-type(3) {
                    transition-delay: calc(.4s * 3) !important;
                }
                &:nth-of-type(4) {
                    transition-delay: calc(.4s * 4) !important;
                }
                &:nth-of-type(5) {
                    transition-delay: calc(.4s * 5) !important;
                }
            }
        }
        // #story_second {
        //     grid-column: 1;
        //     grid-row: 2;
        // }
    }
</style>
