<template>
    <div class="page max-width anim-fadeIn">
        <div class="page-header" >
            <h1 class="anim-fadeIn" v-rellax="{speed: -2, }">{{$t('contact.hero.header')}}</h1>
            <!-- {{ $t('message.hello', { msg: 'hello' }) }} -->
            <!-- <p class="anim-fadeIn" v-rellax="{speed: -2, }">Contact our sales agent for inquiries</p> -->
        </div>
        <!-- <div class="contact-row">
            <h1 class="anim-fadeIn">.. ..</h1>
            <p class="anim-fadeIn">Global Sales Agent</p>
            <a class="contact-item anim-fadeIn"
               v-sp-ripple="'white'"
               href="mailto:.....@jaure.se"
            >
                <i class="mdi mdi-email"></i>
                .....@jaure.se
            </a>
            <a class="contact-item anim-fadeIn"
               v-sp-ripple="'white'"
               href="tel:+46 72 122 34 56"
            >
                <i class="mdi mdi-phone"></i>
                +46 72 122 34 56
            </a>
        </div> -->
        <div class="contact-row">
            <a class="contact-item anim-fadeIn"
               v-sp-ripple="'white'"
               href="mailto:contact@jaure.se"
            >
                <i class="mdi mdi-email"></i>
                contact@jaure.se
            </a>
        </div>
        <div class="contact-row">
            <h1 class="anim-fadeIn">{{$t('contact.location.1.header')}}</h1>
            <p class="anim-fadeIn">
                Volgsjövägen 30B
                <br/>
                912 23 Vilhelmina, Sweden
            </p>
        </div>
        <div class="contact-row">
            <h1 class="anim-fadeIn">{{$t('contact.location.2.header')}}</h1>
            <p class="anim-fadeIn">
                10 rue Lagarde
                <br/>
                75005 Paris, France
                <br/>
                +33 676 88 29 88
            </p>
        </div>
        <div class="contact-row">
            <h1 class="anim-fadeIn">{{$t('contact.location.3.header')}}</h1>
            <p class="anim-fadeIn">
                7901 4th St N Suite 300
                <br/>
                St. Petersburg, FL 33702
                <br/>
                +1 (813) 252-1821
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            // remove cuz cookies
            // this.$gtag.pageview({ page_title: "track-Contact" });
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";

    .contact-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 20px 0 0 0;
        padding: 0 0 40px 0;
        align-items: start;

        &:last-of-type {
            margin-bottom: 120px;
        }

        &::after {
            content: "";
            display: flex;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 1px;
            background: rgba(white, .1);
        }

        h1 {
            font-size: 1.1rem;
            letter-spacing: .05rem;
            color: white;
            animation-duration: 2.5s !important;
            animation-delay: .2s !important;
        }

        p {
            animation-duration: 2s !important;
            animation-delay: .5s !important;
            color: rgba(white, .8);
            font-size: .9rem;
            letter-spacing: .05rem;
            // line-height: 1.1rem;
            margin: 0 0 10px 0;
        }

        .contact-item {
            animation-duration: 2s !important;
            animation-delay: .8s !important;
            margin: 8px 0 0 0;
            font-size: .9rem;
            padding: 0 10px;
            //border: 2px solid transparent;
            border-radius: 4px;
            color: rgba(white, .8);
            transition: .2s;
            font-family: "Montserrat", sans-serif;

            i {
                font-size: .9rem;
                margin: 0 5px 0 0;
                color: $colorGold;
            }

            &:hover {
                background: rgba(black, .2);
                color: rgba(white, 1);
                //border: 2px solid $colorGold;
            }
        }
    }
</style>
