<template>
    <router-view id="app"/>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        data() {
            return {
                window: {
                    width: 0,
                    height: 0,
                },
            };
        },
        created() {
            window.addEventListener("resize", this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener("resize", this.handleResize);
        },
        methods: {
            ...mapActions("cases", ["GET_CASES", "ADD_CASE"]),
            handleResize() {
                const shouldBeMobile = window.innerWidth <= 600;
                const { isMobile } = this.$store.state;
                if (shouldBeMobile && !isMobile) {
                    this.$store.commit("setIsMobile", true);
                    // console.log("switched to mobile");
                } else if (!shouldBeMobile && isMobile) {
                    this.$store.commit("setIsMobile", false);
                    // console.log("switched to desktop");
                }
            },
        },
    };
</script>

<style lang="scss">
    @import "@/assets/scss/site.scss";
    @import "@/assets/scss/animations.scss";

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        min-width: 100%;
        min-height: 100%;

    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }
</style>
