<template>
    <div>
        ...
    </div>
</template>

<script>
    export default {
        created() {
            this.$router.replace({
                name: "v3-jaure",
                params: {
                    // lang: "en",
                },
            });
        },
    };
</script>
