<template>
    <div class="cursor"
         :class="{'cursor-down': isDown}"
         :style="cursorStyle"
    >
        <div class="cursor-scroll"
             :class="{'cursor-scroll-show': scrollShow}"
        >
            <div :style="scrollStyle"></div>
        </div>
        <!-- <div class="cursor-inner"></div> -->
        <!-- x: {{offsetY}} -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                runningTimer: null,
                clickTimer: null,
                isDown: false,
                offsetX: 0,
                offsetY: 0,
                scrollShow: false,
                scrollPercentage: 0,
                x: 0,
                y: 0,
            };
        },
        computed: {
            cursorStyle() {
                let result = "";
                result += `left:${this.x}px;`;
                result += `top:${this.y}px;`;
                return result;
            },
            scrollStyle() {
                let result = "";
                result += `top:${this.scrollPercentage}%;`;
                return result;
            },
        },
        created() {
            window.addEventListener("mousemove", this.handleMouseMove);
            window.addEventListener("mousedown", this.handleMouseDown);
            window.addEventListener("mouseup", this.handleMouseUp);
            window.addEventListener("scroll", this.handleScroll);
        },
        mounted() {

        },
        destroyed() {
            window.removeEventListener("mousemove", this.handleMouseMove);
            window.removeEventListener("mousedown", this.handleMouseDown);
            window.removeEventListener("mouseup", this.handleMouseUp);
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            handleMouseMove(e) {
                this.x = e.clientX;
                this.y = e.clientY;
                this.scrollShow = false;
            },
            handleMouseDown() {
                this.isDown = true;
                this.scrollShow = false;
                clearTimeout(this.clickTimer);
            },
            handleMouseUp() {
                // this.isDown = false;
                clearTimeout(this.clickTimer);
                this.clickTimer = setTimeout(() => {
                    this.isDown = false;
                }, 80);
            },
            handleScroll() {
                this.offsetX = window.scrollX;
                this.offsetY = window.scrollY;

                const h = document.documentElement;
                const b = document.body;
                const st = "scrollTop";
                const sh = "scrollHeight";

                const percent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
                this.scrollPercentage = percent;

                this.scrollShow = true;
                clearTimeout(this.runningTimer);
                this.runningTimer = setTimeout(() => {
                    this.scrollShow = false;
                }, 400);
            },
        },
    };
</script>


<style lang="scss">
    // .cursor-link {
    //     transform: scale(1);
    //     &:hover {
    //         transform: scale(1.1);
    //     }
    // }
</style>

<style lang="scss">
    @import "@/assets/scss/variables.scss";

    @media only screen and (min-width: $medium-trigger-width) {
        html {
            cursor: none;
        }
        .cursor {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            position: fixed;
            z-index: 998;
            pointer-events: none;
            //background: gold;
            //mix-blend-mode: hard-light;
            //@include colorGold;

            &:before {
                z-index: 998;
                content: "";
                display: flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transform-origin: center;
                width: 100%;
                height: 100%;
                border: 2px solid rgba(white, .8);
                background: rgba($colorPurple, .8);
                border-radius: 15px;
                transition: width .2s, height .2s, transform .3s;
                //@include colorGold;
            }

            // click effect
            &:after {
                content: "";
                display: flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(.9);
                transform-origin: center;
                width: 100%;
                height: 100%;
                border-radius: 15px;
                background: rgba(white, .1);
                transition: width .2s, height .2s, transform .2s;
                //@include colorGold;
            }

            &.cursor-down {
                &::before {
                    transform: translate(-50%, -50%) scale(.8);
                }
                &::after {
                    // height: 15px;
                    // width: 15px;
                    transform: translate(-50%, -50%) scale(2.5);
                }

            }

            // .cursor-inner {
            //     width: 100%;
            //     height: 100%;
            //     background: white;
            //     mix-blend-mode: difference;
            // }

            .cursor-scroll {
                width: 8px;
                height: 15px;
                position: absolute;
                right: -15px;
                top: 50%;
                transform: translate(0, -50%);
                opacity: 0;
                transition: opacity .2s;

                &.cursor-scroll-show {
                    opacity: 1;
                }

                &:before {
                    content: "";
                    display: flex;
                    position: absolute;
                    top: -4px;
                    right: 0;
                    bottom: -4px;
                    left: 0;
                    border-radius: 10px;
                    background: rgba(white, .6);
                    //border: 2px solid rgba($colorPurple, 1);
                }

                div {
                    transform: translate(0, -50%);
                    border-radius: 50%;
                    width: 4px;
                    height: 4px;
                    left: 2px;
                    background: rgba($colorPurple, .9);
                    position: absolute;
                }
            }
        }
    }

</style>
