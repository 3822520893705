import spatialBeta from "@hagbardpaulson/spatial-beta/dist/@hagbardpaulson/spatial-beta";

import Vue from "vue";
import VueRellax from "vue-rellax";
// import VueGtag from "vue-gtag"; // remove cuz cookies
import i18n from "./plugins/i18n";
import App from "./areas/App.vue";
import store from "./store/store";

import "@mdi/font/css/materialdesignicons.min.css";
import "@hagbardpaulson/spatial-beta/dist/@hagbardpaulson/spatial-beta.min.css";
import "@hagbardpaulson/spatial-beta/dist/directives";
import "./assets/themes/default.scss";


import router from "./router";

// map layouts
import LayoutEmpty from "./areas/shared/layouts/LayoutEmpty.vue";
import LayoutToolbar from "./areas/shared/layouts/LayoutToolbar.vue";
import PageGrid from "./areas/shared/layouts/PageGrid.vue";
import PageGridChild from "./areas/shared/layouts/PageGridChild.vue";

// map layouts, theese are applied via the route meta
Vue.component("layout-empty", LayoutEmpty);
Vue.component("layout-toolbar", LayoutToolbar);
Vue.component("page-grid", PageGrid);
Vue.component("page-grid-child", PageGridChild);

// use spatial components
Vue.use(spatialBeta);
Vue.use(VueRellax);


// Vue.use(VueI18n, {
//     locale: "en", // set locale
//     fallbackLocale: "en",
//     messages, // set locale messages
// });

// === REMOVE CUZ COOKIES
// Vue.use(VueGtag, {
//     config: {
//         id: "G-ER1R0NMF5X",
//         params: {
//             send_page_view: false,
//         },
//     },
//     appName: "Jaure Web",
//     pageTrackerScreenviewEnabled: false,
//     // pageTrackerTemplate(to) {
//     //     return {
//     //         page_title: to.track_title,
//     //         page_path: to.path,
//     //     };
//     // },
// });

Vue.config.productionTip = false;

// const i18n = new VueI18n({
//     locale: "en", // set locale
//     fallbackLocale: "en",
//     messages, // set locale messages
// });

new Vue({
    router,
    store,
    i18n,
    // data: globalData,
    // methods: globalMethods,
    render: h => h(App),
}).$mount("#app");
