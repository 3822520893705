<template>
    <!-- <img :src="imageSource" @load="onImgLoad"/> -->
    <div class="async-image" :style="{
        'background-image': 'url('+ url +')'
    }"></div>
</template>

<script>
    export default {
        props: ["image", "url"],
        computed: {
            imageSource() {
                if (this.$store.state.isMobile && this.image.imgMobile) {
                    return this.image.imgMobile;
                }
                return this.image.img;
            },
        },
        methods: {
            onImgLoad() {
                this.image.loaded = true;
                // this.$emit("loaded", this.img.id);
            },
        },
    };
</script>


<style lang="scss" scoped>
    .async-image {
        background-size: cover;
        background-position: center;
    }
</style>
