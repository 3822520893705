/* eslint-disable max-len */
export default {
    greeting: "Welcome to Jaure",
    menu: {
        jaure: "JAURE",
        about: "عن مياهنا",
        story: "القصة",
        products: "منتجاتنا",
        contact: "للتواصل",
    },
    start: {
        hero: {
            header: "أنقى المياه",
            subheader: "معبأة في المصدر، من صخر الأديم العريق في شمال اسكندنافيا.",
        },
        story: {
            header: "دعونا نروي لكم قصتنا",
            part1: `JAURE هي شركة سويدية عائلية تهتم بالجودة والاستدامة والمسؤولية الاجتماعية. نريد أن نشارك العالم بأروع الهدايا - ماء نقي كالكريستال، ناعم ورائع الطعم، معبأ في المصدر من أرضنا.`,
            part2: `على مدى ستة أجيال، امتلكت عائلتنا هذه الأرض واستفادت منها. الماء جزء لا يتجزأ من كل شيء هنا، وقد نشأنا على قصص عن قدراته الحيوية. في الصيف، تكون الغابة كثيفة ورطبة، والأغصان تنحني تحت وطأة الطحالب، وهي علامة على الهواء النقي الغني بالأكسجين. وتغطي الزهور والأعشاب البرية التلال. وفي الشتاء، يهمس الماء تحت طبقة كثيفة من الثلج. تشتهر منطقتنا أيضًا بكونها أرض صيد من الدرجة الأولى، حيث تجذب الأيائل والدببة والحيوانات البرية الأخرى التي تزدهر على العشب الطازج والمياه الغنية بالمعادن. في حين أننا كنا دائمًا نعتبر مياه JAURE أمرًا مسلمًا به، فإن الماء أصبح موردًا نادرًا بشكل متزايد في العالم.`,
            part3: `ينبع ينبوع JAURE من عمق 160 مترًا في صخر الجرانيت القديم الذي يعود إلى ملياري عام. هناك في أعماق الجبل، يحافظ نظام مغلق طبيعيًا على الخصائص الفريدة للمياه، مما يحميها من أي تأثير بشري أو بيئي. تحتوي المياه على المعادن والأملاح التي يحتاجها الإنسان والحيوان والطبيعة ليشعروا بأفضل حال. والشرح يكمن في الصخور. حيث تحتوي على وفرة من المعادن الطبيعية التي نشأت منذ 500 مليون سنة، عندما ارتفع قاع المحيط وكون سلسلة جبالنا الشمالية.`,
            part4: `لقد تم تناقل القصص حول هذه المياه من جيل إلى جيل. كان أجدادنا يربون الخيول هنا. قوية، ثابتة وصحية بفضل الماء، كما كانوا يقولون. هنا، على ضفاف بحيرة Vojmsjön في Vilhelmina، تم العثور على واحدة من أقدم آثار الوجود البشري في شمال السويد: موقد نار عمره 8000 عام. عندما تراجعت الجليد الداخلي، استقر الناس عند حافة المياه، وبنوا حفرة نار وطبخوا وجبات من السمك أو الأيل. ومن المحتمل أن المياه الصافية كانت لها جاذبية خاصة بالنسبة لهم. خلال القرن الثامن عشر، أطلق سكان سامي المحليون على بحيرة Vojmsjön اسم "بحيرة القلب" لأن ماءها كان يقال إنه يمنح الناس قلبًا قويًا وصحيًا.`,
            part5: `نظرًا لهذا الإرث، نشعر بامتنان عميق وتقدير لهذه المياه وخصائصها الحيوية الرائعة. لهذا السبب نتعامل معها بأقصى درجات العناية. نحن نستخرجها مباشرة من المصدر ونعبئها في زجاجات جميلة - دون وسطاء أو معالجة أو إضافات - ونقدم هذا المنتج الحصري للعالم. حتى تتمكنوا أنتم أيضًا من الاستمتاع بطعم JAURE.`,
        },
        timeline: {
            1: {
                title: "صخر الأديم العريق",
                text: `
                لقد تشكل هذا الصخر الصلب من الجرانيت، و الذي يمثل أساس الحياة لسكّان شمال السويد، من قبل مليوني عام من الزمان.
                `,
            },
            2: {
                title: "نبع طبيعي",
                text: `
                على عمق مئة وستون مترًا في صخر الأديم هذا، خُلق نبع طبيعي.  مجرى متقرقر من أبرد وأكثر المياه عذوبةً
ونقاءًا. ياوري Jaure. 
                `,
            },
            3: {
                title: "أنقى المياه",
                text: `
                إن البنيان الداخلي الضخم والراسح لصخر الأديم هو ما يصون النقاء الفريد لهذه المياه، بمنأى تام عن تأثير البشر والبيئة المحيطة. 
                `,
            },
            4: {
                title: "معبأة في المصدر",
                text: `
                نحن نعامل هذه المياه الفريدة بالامتنان والإجلال. إنها معبأة تمامًا في مصدر النبع، خالية من أي وسيط، أو معالجة أو إضافات، هكذا تتم ولادة ياوري Jaure.
                `,
            },
            5: {
                title: "مُقدمة على مائدتك",
                text: `
                يتم شحن كمية حصرية من مياه ياوري حول العالم لتنتهي بتقديمها على مائدتك. حتى تستمع أنت أيضًا بعذوبة أنقى المياه.
                `,
            },
        },
    },
    about: {
        hero: {
            header: "أنقى المياه، معبأة في المصدر من صخر الأديم",
            subheader: "المياه النقية هي أساس كل حياة على وجه الأرض. إن البشر بحاجة إليها لينتجوا الطاقة والغذاء، وليبقوا أصحاء ويضمنوا التنمية المستدامة.",
        },
        section1: {
            header: "الجودة والفخر",
            text: `تم تأسيس ياوري Jaure في عام ٢٠٢١ والتي تصدّر أجود المياه إلى السوق الواعي في كل أنحاء العالم.  مياهنا تأتي من نبعها
            الخاص في الأنحاء الشمالية من مملكة السويد وتجلب معاها تجربة جديدة متكاملة لمذاق المياه النقية. تنعكس قيمنا الأساس في
            كل ما نفعل وفي نطاق منتجاتنا: الجودة، الاستدامة، العقلية المناصرة للبيئة، المسؤولية الاجتماعية والتتبع هي أساس كل أنشطتنا.`,
        },
        section2: {
            header: "روّاد المياه النقية",
            text: `تتم إدارة ياوري Jaure من قبل الشركة العائلية "حديقة كاترينا". يحوي تاريح عائلتنا على الكثير من الحكايا التي تناقلتها الأجيال
            جيلًا بعد جيل، حكايا تشهد على النقاء الفريد لنبع مياهنا وما تعنيه للناس والبيئة. هذه الحكايات هي ضمانك بأننا نقدم مياه 
            نقية، ونعاملها بحب وإجلال كبيرين. تحتوي ياوري Jaure على تركيز عالٍ جدًا من جزيئات الماء، ويتم منح خصائصها الفريدة 
            أعلى التقييمات الممكنة في جميع اختبارات الجودة. إنها مياه ناعمة المذاق وقلوية ذات جودة عالية وغنية بالمعادن الطبيعية، 
            غير ملوثة بالنترات.
            `,
        },
        section3: {
            header: "الاستدامة في كل خطوة",
            text: `تعمل ياوري Jaure بطريقة مستدامة ومتكيفة مع المناخ، وهذا ينطبق على كل خطوة، بدءًا من عمليات التصنيع وموظفينا 
            إلى التعامل مع المنتجات والشحن والتسليم. نحن نؤمن بأن المياه النظيفة هي مورد استراتيجي للتنمية المجتمعية، ونعلم بأنها 
            ضرورية لعمل نظمنا البيئية، لإنتاج الطاقة، وللقطاعات الخضراء والصناعات والمنازل. هدفنا هو أن تتفهم الأجيال القادمة أيضًا 
            وتقدر أهمية المياه - لأنفسهم وبيئتنا ومستقبلنا.
            `,
        },
    },
    story: {
        hero: {
            header: "الأصل",
        },
        section1: {
            text: "على منحدر تلٍ محاط بالغابات الخضراء المورقة في شمال السويد، يتفجر نبع في صخر الأديم العريق، مصدر أنقى أشكال المياه. لقد اكتسب الإنسان والحيوان والطبيعة على حد سواء حيويتهم من هذا المجرى ناعم التقرقر لآلاف السنين، من المياه النقية الكريستالية. ياوري Jaure.",
        },
        section2: {
            text: "يأتي إرث هذه المياه من هذا المكان بالذات، والذي توارثه جيلٌ بعد جيل. إنه إرث مصحوب بالامتنان والإجلال للمياه الباردة والعذبة والصافية ولخصائصها الرائعة التي تمنح الحياة.",
        },
        section3: {
            text: "تشكّل هذه المياه جزءًا من كل شيء هنا.  في الصيف، تصبح الغابة كثيفة ومورقة، وتنحني الأغصان منخفضةً مع الأشنة، وهي علامة على الهواء النظيف الغني بالأكسجين. تغطي الأزهار والأعشاب سفوح التلال، وتنتشر بذورها بعيدًا عبر الجداول والأنهار. وفي فصل الشتاء، تُسمع قرقرة المياه من تحت طبقة الجليد السميكة.",
        },
        section4: {
            text: "هناك علامات واضحة في المنطقة المحيطة لهذه المياه، مما تشير إلى وجود مستوطنات تعود إلى العصر الحجري. يُعتقد أن الينابيع الطبيعية جذبت الناس إلى هذا المكان، حيث كانت المياه صافية لدرجة أن صفاءها يوحي بأن الحجارة في أعماق القاع قريبة وفي متناول اليد.",
        },
        section5: {
            text: "ينبثق نبع ياوري Jaure من مصدره، على عمق مئة وستين مترًا في قاعدة صخرية من الجرانيت يبلغ عمرها ما يقارب ملياري عام. يوجد في أعماق الجبل نظام مغلق طبيعيًا يصونُ النقاء الفريد للمياه ويحميها من أي تأثير بشري أو بيئي. يتم سحب المياه في عبوات زجاجية قابلة لإعادة التدوير من المصدر مباشرة، ثم يتم توزيع كميات محدودة وحصرية  منها في جميع أنحاء العالم. جالبين لك عذوبة أنقى المياه.",
        },
    },
    products: {
        hero: {
            header: "منتجات ياوري Jaure",
            subheader: "من أعماق الجبل في نظام مغلق طبيعياً يحافظ على نقاء المياه الفريد ويحميها من أي تأثير بشري أو بيئي.",
        },
        signature: {
            header: "قنينتنا المميزة",
            description: `قنينة بحجم ٢٥٠ مل مريحة في الحمل وتتناسب تمامًا مع حقيبة اليد أو حقيبة السفر.
            لترطيب استثنائي أثناء التنقل.
            `,
            size: "٢٥٠ مل",
        },
        grande: {
            header: "قنينة قراندي",
            description: "يتم تعبئتها في قوارير زجاجية حصرية وقابلة لإعادة التدوير من المصدر مباشرة، لتجلب لك متعة عذوبة أنقى المياه. مثالية للعشاءات الفخمة والمناسبات الحصرية.",
            size: "٧٠٠ مل",
        },
        aviator: {
            header: "صندوق الطيار",
            description: "أنقى المياه، معبأة في المصدر من صخر الأديم العريق، مثالية الحجم لليخت أو الطائرة الخاصة.",
            size: "٣ لتر / ٥ لتر",
        },
        minerals: {
            header: "بخصائص معدنية متميزة",
            row1: {
                header: "٥٠ مجم / لتر",
                subheader: "كالسيوم",
            },
            row2: {
                header: "٤,٤ مجم / لتر",
                subheader: "مغنيسيوم",
            },
            row3: {
                header: "٤,٩ مجم / لتر",
                subheader: "نتريوم",
            },
            row4: {
                header: "٧,٩",
                subheader: "درجة الحموضة",
            },
            row5: {
                header: "١,٠ مجم / لتر",
                subheader: "كاليوم",
            },
            row6: {
                header: "< ١,٠ مجم / لتر",
                subheader: "كلوريدات",
            },
        },
    },
    contact: {
        hero: {
            header: "اتصل بنا",
        },
        location: {
            1: {
                header: "المكتب الرئيسي",
            },
            2: {
                header: "غرفة عرض باريس",
            },
        },
    },
};
