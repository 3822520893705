<template>
    <div class="app-layout" :class="seperator">
        <slot name="overlay"></slot>
        <header id="app_toolbar" v-bind:style="{ height: toolbarHeight }">
            <div id="slot">
                <slot name="toolbar"></slot>
            </div>
            <div class="app-toolbar-effect sp-z-3"
                 v-bind:style="{ opacity: shadowOpacity }">
            </div>
        </header>
        <div id="app_toolbar_push" v-bind:style="{ height: toolbarHeight }"></div>
        <main id="app_body">
            <slot @setExtended="setExtended"/>
        </main>
        <footer>
            <slot name="footer"></slot>
        </footer>
        <sp-overlay/>
    </div>
</template>

<script>
    export default {
        name: "LayoutToolbar",
        props: ["seperator", "extended", "scrollOffset"],
        data() {
            return {
                shadowOpacity: 0,
                alerts: [],
            };
        },
        computed: {
            toolbarHeight() {
                if (this.extended && this.$store.state.isMobile) {
                    return "128px";
                }
                return "64px";
            },
        },
        created() {
            window.addEventListener("scroll", this.handleScroll);
            this.handleScroll();


            // global event for displaying alerts
            this.$root.$on("alert", (alert) => {
                this.alerts.push(alert);
            });
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            setExtended(extended) {
                this.extended = extended;
            },
            handleScroll() {
                const { scrollY } = window;
                const scrollTarget = 50;
                const target = 1;
                // Any code to be executed when the window is scrolled
                const percentage = (scrollY - this.scrollOffset) / scrollTarget;
                const value = Math.max(0, Math.min(target, (target) * percentage));
                // if (value !== lastOpacity) {
                //     $toolbarEffect.css("opacity", value);
                //     lastOpacity = value;
                // }
                if (value <= 1) {
                    this.shadowOpacity = value;
                } else {
                    this.shadowOpacity = 1;
                }
            },
        },
    };
</script>

<style lang="scss">
    body {
        overflow-y: scroll;
    }
</style>


<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/layout.scss";
    //@import "@/assets/scss/layout.scss";
    @import "@hagbardpaulson/spatial-beta/dist/base/helpers.scss";

    #app {
        // position: absolute;
        // left: 0;
        display: grid;
        width: 100%;
        grid-template-rows: minmax(min-content, max-content) 1fr;

        #app_toolbar {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            background: $color010;
            grid-column: 1;
            grid-row: 1;
            width: 100%;
            height: 64px;
            position: fixed;
            z-index: 59;


            #slot  {
                padding-top: 16px;
                padding-bottom: 16px;
                display: flex;
                align-items: center;
                height: 64px;
                @include notch-padding-left();
                @include notch-padding-right();

                @media only screen and (max-width: $mobile-trigger-width) {
                    &.extended {
                        height: calc(64px + 64px);
                    }
                }

                #action {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: auto;
                    height: 32px;

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $color300;
                        font-size: 1.5rem;
                        margin: auto 4px auto -4px;
                        padding: 0;
                        line-height: 32px;
                        height: 32px;
                        width: 32px;
                        cursor: pointer;
                        border-radius: 4px;
                        transition: .2s;
                        position: relative;

                        &:hover {
                            color: $color400;
                        }

                        i {
                            line-height: 32px;
                            height: 32px;
                            width: 32px;
                            font-size: 1.2rem;
                        }
                    }

                    img {
                        width: 18px;
                        height: 18px;
                        margin: auto 8px auto -4px;
                    }
                }

                h1 {
                    //color: $color300;
                    font-size: 0.9rem;
                }

                .breadcrumbs {
                    padding: 6px 0 6px 15px;
                    border-left: 1px solid $color100;
                    margin: 0 0 0 15px;
                }
            }

            #app_toolbar_right {
                display: flex;
                margin: 16px 0;
                grid-column: 2;

                .toolbar-profile,
                .toolbar-apps,
                .toolbar-notifications {
                    margin-left: 10px;
                }

                .toolbar-profile {
                    width: 32px;
                    height: 32px;
                    @include notch-margin-right();
                }
            }

            .app-toolbar-effect { // effect for fading drop shadow
                background: white;
                z-index: -1;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        #app_toolbar_push {
            // toolbar push, since toolbar is fixed
            height: 64px;
            grid-column: 1;
            grid-row: 1;
            transition: .2s;

            @media only screen and (max-width: $mobile-trigger-width) {
                &.extended {
                    height: calc(64px + 64px);
                }
            }
        }

        main {
            grid-column: 1;
            grid-row: 2;
        }
    }
</style>
