<template>
    <div>
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "LayoutEmpty",
    };
</script>

<style lang="scss" scoped>
    #app {
        display: flex;
        flex-direction: column;
    }
</style>
