<template>
    <sp-modal id="modal_create_project"
              header=""
              :enabled="modalEnabledId === 'modal_create_project'"
              :error="modalError"
              @hide="setModalEnabledId(null)"
              width="450px" height="600px"
              ref="modal"
    >
        <sp-modal-page id="page_create" name="page1" visible="true" :scroll="false" :footer="false">
            <div>
                <div @click="setLanguage('se')">
                    Svenska
                </div>
                <div @click="setLanguage('en')">
                    Engelska
                </div>
            </div>
            <template v-slot:footer>
                <sp-button class="sp-button prev" @click="setModalEnabledId(null)">Avbryt</sp-button>
                <sp-button
                    class="sp-button next"
                    @click="submit()"
                >
                    Skapa
                </sp-button>
                <!-- <sp-button class="sp-button next" @click="$refs.modal.stepToNext">Skapa</sp-button> -->
            </template>
        </sp-modal-page>
    </sp-modal>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";

    // mixins
    // import organisationMixin from "@/areas/shared/mixins/organisationMixin";

    export default {
        // mixins: [organisationMixin],
        data() {
            return {
                modalError: false,
                modalLoading: false,
                formDisplayName: "",
                formAllUsers: true,
            };
        },
        computed: {
            ...mapGetters("app", ["modalEnabledId"]),
        },
        methods: {
            ...mapActions("app", [
                "setModalEnabledId",
            ]),

            setLanguage(lan) {
                // console.log(lan);
                this.$router.replace({
                    name: "v3-jaure",
                    params: {
                        lang: lan,
                    },
                });
                this.setModalEnabledId(null);
            },
        },
    };
</script>


<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";

    /deep/ .sp-modal-page-content {
        overflow-y: auto;
    }
</style>
