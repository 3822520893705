<template>
    <div :id="id" class="page page-card-grid page-main anim-fadeIn sp-z-1">
        <div class="page-card-header">
            <router-link
                class="back"
                :to="back">
                <i class="mdi mdi-chevron-left"></i>
                <h2>Tillbaka</h2>
            </router-link>
            <h1>{{header}}</h1>
            <div class="action">
                <slot name="action"></slot>
            </div>

            <!-- <sp-button id="button_save" class="sp-button" @click="update()">Spara</sp-button> -->
        </div>
        <div class="page-card-menu" v-if="menuItems && menuItems.length > 0">
            <sp-treemenu class="anim-fadeIn">
                <sp-treemenu-item
                    v-sp-ripple
                    v-for="menuItem in menuItems"
                    :key="menuItem.title"
                    class="anim-fadeIn"
                    v-bind:class="{ 'selected': $route.name== menuItem.route}"
                >
                    <i :class="'mdi mdi-' + menuItem.icon"></i>
                    <h4>{{menuItem.title}}</h4>
                    <router-link
                        v-if="menuItem.route"
                        :to="{ name: menuItem.route, params: {organisationId: $route.params.organisationId } }"
                        class="a-overlay"
                    />
                </sp-treemenu-item>
            </sp-treemenu>
        </div>
        <div class="page-card-scroll">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: null,
            header: {
                type: String,
                default: () => "...",
            },
            menuItems: {
                type: Array,
                default: () => [],
            },
            back: {},
        },
    };
</script>

<style lang="scss" scoped>

</style>
