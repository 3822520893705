<template>
    <layout-toolbar seperator="layout-main-v3 anim-fadeIn"
                    :scrollOffset="0"
    >
        <template v-slot:overlay>
            <sp-cursor/>
            <sp-mobile-menu :enabled="!$route.meta.hasMobileMenu && mobileMenuEnabled"
                            :class="{ 'rtl': $route.params.lang === 'ar'}"
                            @hide="setMobileMenuEnabled(false)"
            >
                <languange-dropdown
                    id="languange_dropdown_mobile"
                    align="bottom-left"
                    expand="bottom-right"
                />
                <div v-for="(item, key) in menuItems" :key="key"
                     :class="{ 'active': $route.meta.root== item.route}"
                     class="nav-item"
                     @click="openTab(item.route)"
                >
                    <h4>
                        {{item.title}}
                    </h4>
                </div>
            </sp-mobile-menu>
            <!-- <splash/> -->
        </template>
        <template v-slot:toolbar>
            <div id="action" >
                <template v-if="$store.state.isMobile">
                    <button v-if="$route.meta.isRoot == true"
                            class="sp-button show-on-root anim-fadeIn"
                            @click="setMobileMenuEnabled(true)"
                    >
                        <i class="mdi mdi-menu"></i>
                    </button>
                    <button v-else-if="$store.state.breadcrumbs[$store.state.breadcrumbs.length - 2]"
                            class="sp-button show-on-root anim-fadeIn"
                            @click="$router.push({
                                name: ($store.state.breadcrumbs[$store.state.breadcrumbs.length - 2]).name
                            })"
                    >
                        <i class="mdi mdi-chevron-left"></i>
                    </button>
                    <button v-else
                            class="sp-button show-on-root anim-fadeIn"
                            @click="$router.go(-1)"
                    >
                        <i class="mdi mdi-chevron-left"></i>
                    </button>
                </template>
                <template v-else>
                    <!-- <button class="sp-button show-on-root anim-fadeIn"
                            @click="setMobileMenuEnabled(true)"
                    >
                        <i class="mdi mdi-menu"></i>
                    </button> -->
                    <!-- <img src="@/assets/images/logos/time.png"/> -->
                </template>
            </div>
            <div v-if="$store.state.isMobile" class="toolbar-mobile anim-fadeIn">
                <h1>{{$t(`menu.${$route.meta.displayName.toLowerCase()}`)}}</h1>
                <img :class="{ 'rtl': $route.params.lang === 'ar' }" src="@/assets/images/logo_xsmall.png">
            </div>
            <div v-else id="toolbar_nav" v-bind:class="{ 'show-promo': showPromo }">
                <img :class="{ 'rtl': $route.params.lang === 'ar' }" src="@/assets/images/logo_gold.png"
                     @click="openTab('v3-jaure')"
                >
                <!-- <logo class="nav-item">

                </logo> -->
                <!-- <img src="@/assets/images/logo.svg"/> -->
                <div v-for="(item, key) in menuItems" :key="key"
                     :class="{
                         'active': $route.meta.root== item.route,
                         'expanded': $route.meta.expanded == true
                     }"
                     class="nav-item cursor-link"
                     @click="openTab(item.route)"
                >
                    <h4>
                        {{item.title}}
                    </h4>
                    <!-- <a class="a-overlay" @click="$scrollToId(item.route)"></a> -->
                    <!-- <router-link
                        :to="{ name: item.route, params:
                            {
                                //organisationUrl: $route.params.organisationUrl,
                                //teamId: menuItem.teamId
                            }
                        }"
                        class="a-overlay"
                    /> -->
                </div>
                <languange-dropdown/>
            </div>
        </template>
        <div id="app_content">
            <div id="page_background">
                <async-img :url="require('@/assets/images/astro_v6/mountain1.jpg')"/>
            </div>
            <router-view></router-view>
        </div>
        <div id="app_footer">
            <div id="app_footer_content">
                <h1>Jaure</h1>
                <p v-bind:class="{ 'rtl': $route.params.lang === 'ar' }">© Jaure</p>
            </div>
        </div>

        <!-- REMOVE CUZ COOKIES -->
        <!-- <div id="cookie_concent" v-if="!cookieConcentAccepted">
            <div id="cookie_concent_inner">
                <i class="mdi mdi-information"></i>
                <p>This website uses cookies to improve your experience.
                    <router-link
                        :to="{
                            name: 'v3-policy' }"
                    >
                        Read more
                    </router-link>
                </p>
                <sp-button class="sp-button" v-sp-ripple="'#32E2CA'" @click="accept">
                    <template v-if="!this.$store.state.isMobile">Ok, don't show again</template>
                    <template v-else>Ok</template>
                </sp-button>
            </div>
        </div> -->

        <!-- MODALS -->
        <modal-locale/>
        <sp-overlay :enabled="overlayEnabled" @hide="setOverlayEnabled(false)"/>
        <auto-video :show="showPromo" @close="closePromo"/>
    </layout-toolbar>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import Vue from "vue";

    // mixins
    import CookieConcent from "@/areas/v3/mixins/CookieConcent";

    // components
    import LanguangeDropdown from "@/areas/v3/components/LanguangeDropdown.vue";
    import SpatialCursor from "@/areas/v2/components/SpatialCursor.vue";
    import AsyncImage from "../components/AsyncImage.vue";
    import modalLocale from "../components/ModalLocale.vue";
    import AutoVideo from "../components/AutoVideo.vue";

    Vue.prototype.$scrollToTop = () => {
        // window.scrollTo(0, 0);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    Vue.prototype.$scrollToId = (refName, addOffset) => {
        const element = document.getElementById(refName);
        const top = element.offsetTop;
        let offset = 64 + 20;
        if (addOffset) {
            offset += addOffset;
        }
        // window.scrollTo(0, top - offset);
        window.scrollTo({
            top: top - offset,
            behavior: "smooth",
        });
    };

    export default {
        components: {
            "languange-dropdown": LanguangeDropdown,
            "sp-cursor": SpatialCursor,
            "async-img": AsyncImage,
            "modal-locale": modalLocale,
            "auto-video": AutoVideo,
        },
        data() {
            return {
                cookieConcentAccepted: false,
                showPromo: false,
                menuItems: [
                    // {
                    //     title: "JAURE",
                    //     route: "v3-jaure",
                    // },
                    // {
                    //     title: this.$t("menu.about"),
                    //     route: "v3-about",
                    // },
                    // {
                    //     title: this.$t("menu.story"),
                    //     route: "v3-story",
                    // },
                    // {
                    //     title: this.$t("menu.products"),
                    //     route: "v3-products",
                    // },
                    // {
                    //     title: this.$t("menu.contact"),
                    //     route: "v3-contact",
                    // },
                ],
            };
        },
        computed: {
            ...mapGetters("app", ["overlayEnabled", "mobileMenuEnabled"]),
            ...mapGetters(["isMobile"]),
            isLoaded() {
                let isLoaded = true;
                Object.keys(this.images).forEach((key) => {
                    const image = this.images[key];
                    if (image.loaded === false) {
                        isLoaded = false;
                    }
                });
                return isLoaded;
            },
        },
        watch: {
            $route(to, from) {
                if (to.params.lang !== from.params.lang) {
                    // console.log("switch");
                    this.loadMenuLocale();
                }
            },
        },
        created() {
            // window.addEventListener("scroll", this.handleScroll);
            // this.handleScroll();
            this.getIsAccepted();
            this.loadMenuLocale();
            if (this.$route.query.promo) {
                this.showPromo = true;
            }
        },
        mounted() {
            const myarr = ["se", "en", "ar"];
            const isValidLocale = (myarr.indexOf(this.$route.params.lang) > -1);

            if (!isValidLocale) {
                // this.setModalEnabledId("modal_create_project");
                this.$router.replace({
                    name: this.$route.name,
                    params: {
                        lang: "en",
                    },
                });
            }
        },
        destroyed() {
            // window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            ...mapActions("app", [
                "setOverlayEnabled",
                "setEnabledModal",
                "setMobileMenuEnabled",
                "setModalEnabledId",
            ]),
            closePromo() {
                this.showPromo = false;
            },
            loadMenuLocale() {
                this.menuItems = [
                    {
                        title: "JAURE",
                        route: "v3-jaure",
                    },
                    {
                        title: this.$t("menu.about"),
                        route: "v3-about",
                    },
                    // {
                    //     title: this.$t("menu.story"),
                    //     route: "v3-story",
                    // },
                    {
                        title: this.$t("menu.products"),
                        route: "v3-products",
                    },
                    {
                        title: this.$t("menu.contact"),
                        route: "v3-contact",
                    },
                ];
            },
            accept() {
                CookieConcent.accept();
                this.getIsAccepted();
            },
            getIsAccepted() {
                this.cookieConcentAccepted = CookieConcent.isAccepted();
                // if (this.cookieConcentAccepted) {
                //     //this.setToolbarOffset(0);
                // }
            },
            // handleScroll() {
            //     const { scrollY } = window;
            //     const scrollTarget = 600;
            //     const target = 1;
            //     const scrollOffset = 600;
            //     // Any code to be executed when the window is scrolled
            //     const percentage = (scrollY - scrollOffset) / scrollTarget;
            //     const value = Math.max(0, Math.min(target, (target) * percentage));
            //     // if (value !== lastOpacity) {
            //     //     $toolbarEffect.css("opacity", value);
            //     //     lastOpacity = value;
            //     // }

            //     if (value <= 1) {
            //         this.page1BackgroundOpacity = value;
            //     } else {
            //         this.page1BackgroundOpacity = 1;
            //     }
            // },
            openTab(route) {
                this.setMobileMenuEnabled(false);

                if (this.$route.name !== route) {
                    window.scrollTo(0, 0);
                    this.$router.push({ name: route });
                } else {
                    this.$scrollToTop();
                }
            },
            scrollTo(offset, callback) {
                const fixedOffset = offset.toFixed();
                const onScroll = () => {
                    if (window.pageYOffset.toFixed() === fixedOffset) {
                        window.removeEventListener("scroll", onScroll);
                        callback();
                    }
                };

                window.addEventListener("scroll", onScroll);
                onScroll();
                window.scrollTo({
                    top: offset,
                    behavior: "smooth",
                });
            },
        },
    };
</script>


<style lang="scss">
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/layout.scss";
    @import "@hagbardpaulson/spatial-beta/dist/base/helpers.scss";

    .layout-main-v3 {
        background: $colorPurple;

        #app_toolbar,
        #app_toolbar #slot,
        #app_toolbar_push {
            height: 48px !important;
        }
        #app_toolbar {
            background: transparent !important;

            #slot {
                display: flex;
                max-width: $max-width;
                width: 100%;
                margin: 0 auto;
                transform: .2s;
                padding-top: 0 !important;
                padding-bottom: 0 !important;


                #toolbar_nav {
                    height: 100%;
                    display: flex;
                    flex: auto;
                    margin: 0 0 0 0;

                    &.show-promo {
                        display: none !important;
                    }

                    img {
                        margin: auto auto auto 0;
                        height: 28px;
                    }

                    img.rtl {
                        margin: auto 0 auto auto;
                        height: 28px;
                    }

                    .nav-item {
                        display: flex;
                        align-items: center;
                        position: relative;
                        margin: 0 0 0 20px;
                        height: 100%;
                        font-size: .8rem;
                        letter-spacing: .05rem;
                        text-transform: uppercase;
                        color: white;
                        opacity: 0.8;
                        //cursor: pointer;
                        overflow: hidden;
                        transition: .2s;
                        font-family: "PlayfairDisplay", serif !important;
                        letter-spacing: .05rem;
                        @include sp-noselect();

                        &:before {
                            content: "";
                            display: flex;
                            position: absolute;
                            right: 15%;
                            bottom: -3px;
                            left: 15%;
                            height: 3px;
                            border-radius: 2px 2px 0 0;
                            opacity: 0;
                            @include colorGold();
                            transition: .2s ease-in-out;
                        }

                        &:hover {
                            opacity: 1;

                            &:before {
                                bottom: 0px;
                                opacity: .8;
                            }
                        }
                        &.active {
                            opacity: 1;
                            color: $colorGold;

                            &:before {
                                opacity: 1;
                                right: 0;
                                bottom: 0px;
                                left: 0;
                            }
                        }
                    }
                }
                #action {
                    .sp-button {
                        color: rgba(white, .8) !important;

                        i {
                            font-size: 1.4rem !important;
                        }
                    }
                }
                > .toolbar-mobile {
                    flex: auto;
                    display: flex;
                    align-items: center;
                    color: white;
                    font-size: 1rem !important;
                    h1 {
                        font-size: 1rem !important;
                        margin: -2px 0 0px 0;
                    }
                    img {
                        width: 28px;
                        margin: 0 0 0 auto;
                    }
                    img.rtl {
                        margin: 0 auto 0 0;
                    }
                }

            }
            .app-toolbar-effect {
                //display: none;
                @include colorBackgroundFaded();
                box-shadow: none !important;
            }
        }
        #app_body {
            z-index: 1;
        }
        #app_content {
            flex-grow: 1;
            flex-shrink: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            min-height: 100%;
            grid-row: 1;

            &.expanded {
                margin-top: -64px;
            }

            #page_background {
                z-index: -1;
                position: fixed;
                top: 0;
                right: 0;
                height: 100vh;
                //bottom: 0;
                left: 0;
                opacity: .2;

                .async-image {
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    object-fit: cover;
                }
            }

            .page {
                display: flex;
                flex-direction: column;
                align-items: center;

                &.max-width  {
                    max-width: $max-width;
                }

                @media only screen and (max-width: $medium-trigger-width) {
                    @include notch-padding-left(25px);
                    @include notch-padding-right(25px);
                }

                .page-header {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 60vh;

                    &::after {
                        content: "";
                        display: flex;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        height: 1px;
                        background: rgba(white, .1);
                    }

                    h1 {
                        font-size: 2rem;
                        color: white;
                        animation-duration: 2.5s !important;
                        animation-delay: 0s !important;
                    }
                    p {
                        font-size: .9rem;
                        color: rgba(white, .8);
                        animation-duration: 2.5s !important;
                        animation-delay: .2s !important;
                    }
                }

                .section {
                    display: flex;
                    flex-direction: column;

                    > h1 {
                        font-size: 1.5rem;
                        color: white;
                        margin: 0 0 5px 0;
                    }
                    > p {
                        font-size: 1rem;
                        color: rgba(white, .8)
                    }
                }

                .main-wrapper {
                    width: 100%;
                    max-width: $max-width;
                    border-radius: 6px;
                    margin-top: 30vh;
                    padding: 15px 15px 30px 15px;
                    color: white;
                    @include colorBackground();

                    @media only screen and (max-width: $medium-trigger-width) {
                        @include notch-padding-left(25px);
                        @include notch-padding-right(25px);
                    }

                    &.animated {
                        animation-name: anim_wrapper;
                        animation-duration: 0.5s;
                        animation-delay: 0;
                        animation-fill-mode: backwards;
                        transform: translate(0, 0);

                        @keyframes anim_wrapper {
                            from {
                                transform: translate(0, 10px);
                            }

                            to {
                                transform: translate(0, 0);
                            }
                        }
                    }


                    .main-wrapper-header {
                        margin: 40px 40px 120px 40px;
                        @media only screen and (max-width: $medium-trigger-width) {
                            margin: 40px 0px 120px 0px;
                        }

                        h1 {
                            animation-duration: 2s !important;
                            animation-delay: 0s !important;
                            font-size: 1.4rem;
                            font-weight: 500;
                            margin: 0px 0 0px 0;
                            text-align: center;
                        }
                        p {
                            //font-family: "Montserrat", sans-serif;
                            font-size: .9rem;
                            text-align: center;
                            color: rgba(white, .7);
                        }
                        span {
                            animation-duration: 1.2s !important;

                            &:nth-child(1) {
                                animation-delay: .2s !important;
                            }
                            &:nth-child(2) {
                                animation-delay: .2s !important;
                            }
                            &:nth-child(3) {
                                animation-delay: .2s !important;
                            }
                        }
                    }
                }

                .page-main-text {
                    max-width: 600px;
                    width: 100%;
                    height: calc(100vh - 48px);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: white;

                    @media only screen and (max-width: $medium-trigger-width) {
                        transform: none !important;
                    }

                    h1 {
                        animation-duration: 2s !important;
                        animation-delay: 0s !important;
                        font-size: 1.4rem;
                        font-weight: 500;
                        margin: -20px 0 2px 0;
                        text-align: center;
                    }
                    p {
                        //font-family: "Montserrat", sans-serif;
                        font-size: .9rem;
                        text-align: center;
                        opacity: .7;
                    }

                    span {
                        animation-duration: 2s !important;

                        &:nth-child(1) {
                            animation-delay: .5s !important;
                        }
                        &:nth-child(2) {
                            animation-delay: 2.5s !important;
                        }
                        &:nth-child(3) {
                            animation-delay: 4.5s !important;
                        }
                    }
                    h4 {
                        animation-duration: 3s !important;
                        animation-delay: 8s !important;
                        font-size: 1.2rem;
                        font-family: "PlayfairDisplay", serif !important;
                    }
                }
            }

        }
        #app_footer {
            // background: rgba($colorPurple, .8) !important;
            // @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            //     background: rgba(29, 39, 80, 0.3) !important;
            //     backdrop-filter: blur(3px);
            // }
            background:  $colorPurple;
            display: flex;
            z-index: 2;
            position: relative;

            #app_footer_content {
                display: flex;
                align-items: center;
                max-width: $max-width;
                width: 100%;
                margin: 0 auto;
                color: white;
                padding-top: 15px;
                @include notch-padding-left;
                @include notch-padding-right;
                @include notch-padding-bottom(15px);

                h1 {
                    font-size: .9rem;
                }
                p {
                    margin: 0 0 0 auto;
                    font-size: .8rem;
                    transition: .2s;
                }
                p.rtl {
                    margin: 0 auto 0 0;
                }
                a:hover{
                    color: $colorAccent;
                }
            }
        }

        #cookie_concent {
            background: rgba($colorPurple, .8);
            width: 100%;
            height: 48px;
            position: fixed;
            z-index: 101;
            bottom: 0;
            display: flex;
            justify-content: center;

            #cookie_concent_inner {
                max-width: $max-width;
                width: 100%;
                display: flex;
                align-items: center;
                @include notch-padding-left();
                @include notch-padding-right();

                @media only screen and (max-width: $medium-trigger-width) {
                    i {
                        display: none;
                    }
                }

                i {
                    color: rgba($color100, .8);
                    margin: 0 10px 0 0;
                }
                p {
                    font-size: .8rem;
                    color: rgba(white, .8);

                    a {
                        margin: 0 0 0 3px;
                        color: white;
                        text-decoration: underline;
                    }
                }
                button {
                    margin: 0 0 0 auto;
                    flex-shrink: 0;
                    //background: rgba(white, .1);
                    font-family: "Montserrat";
                    font-size: .8rem;
                    color: rgba(white, .8);
                    font-weight: 500 !important;
                }
            }
        }

        .sp-mobile-menu {
            width: 250px;
            //background: rgba($colorPurple, .9);
            @include colorBackgroundFaded();
            @include notch-padding-top(40px);
            @include notch-padding-bottom;
            display: flex;
            flex-direction: column;
            gap: 10px;
            opacity: 0;

            &.enabled {
                opacity: 1;
            }

            .nav-item {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                cursor: pointer;
                opacity: 0.8;
                @include notch-padding-left;
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 1.2rem;

                &:before {
                    content: "";
                    display: flex;
                    position: absolute;
                    top: 5px;
                    right: 0;
                    bottom: 5px;
                    width: 3px;
                    border-radius: 2px 2px 0 0;
                    opacity: 0;
                    @include colorGold();
                    transition: .2s ease-in-out;
                }

                &.active {
                    opacity: 1;
                    &:before {
                        opacity: 1;
                    }
                }
            }

            #languange_dropdown_mobile {
                margin: 10px auto 10px auto;
                @include notch-padding-left;

                .content img {
                    width: 40px;
                    background: red !important;
                }
            }

        }

        .sp-mobile-menu.rtl {
            width: 250px;
            //background: rgba($colorPurple, .9);
            @include colorBackgroundFaded();
            @include notch-padding-top(40px);
            @include notch-padding-bottom;
            display: flex;
            flex-direction: column;
            gap: 10px;
            right: 0;
            left: initial;
            transform: translateX(100%);
            opacity: 0;

            &.enabled {
                opacity: 1;
                transform: translateX(0%);
            }

            .nav-item {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                cursor: pointer;
                opacity: .8;
                @include notch-padding-left;
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 1.2rem;

                &:before {
                    content: "";
                    display: flex;
                    position: absolute;
                    top: 5px;
                    right: 0;
                    bottom: 5px;
                    width: 3px;
                    border-radius: 2px 2px 0 0;
                    opacity: 0;
                    @include colorGold();
                    transition: .2s ease-in-out;
                }

                &.active {
                    opacity: 1;
                    &:before {
                        opacity: 1;
                    }
                }
            }

            #languange_dropdown_mobile {
                margin: 10px auto 10px auto;
                @include notch-padding-left;

                .content img {
                    width: 40px;
                    background: red !important;
                }
            }

        }
    }

</style>
