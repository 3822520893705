/* eslint-disable max-len */
export default {
    greeting: "Welcome to Jaure",
    menu: {
        jaure: "JAURE",
        about: "À propos",
        story: "L’histoire",
        products: "Produits",
        contact: "Contact",
    },
    start: {
        hero: {
            header: "Notre hértiage",
            subheader: "Mise en bouteille à la source à partir d'un substrat rocheux ancien, situé dans la partie nord du Royaume de Suède",
        },
        story: {
            header: "Laissez-nous vous raconter notre histoire",
            part1: `JAURE est une entreprise familiale suédoise, passionnée par la qualité, la durabilité et la responsabilité sociale. Nous voulons partager avec le monde les plus beaux des cadeaux – une eau cristalline, douce et d'une qualité exceptionnelle, mise en bouteille à la source sur notre terre.`,
            part2: `Depuis six générations, notre famille possède et utilise cette terre. L'eau est une composante essentielle ici, et nous avons grandi avec les récits de ses pouvoirs vivifiants. En été, la forêt est dense et humide, et les branches se courbent sous le poids des lichens, signe d'un air pur et riche en oxygène. Les collines sont couvertes de fleurs et d'herbes sauvages. Et en hiver, l'eau murmure sous une épaisse couche de neige. Notre région est également réputée pour être un excellent terrain de chasse, attirant élans, ours et autres gibiers sauvages qui prospèrent grâce à l'herbe fraîche et à l'eau riche en minéraux. Bien que nous ayons toujours pris notre eau JAURE pour acquise, l'eau devient une ressource de plus en plus rare dans le monde.`,
            part3: `La source JAURE jaillit à 160 mètres de profondeur dans le substrat granitique vieux de deux milliards d'années. Là, dans les profondeurs de la montagne, un système naturellement fermé conserve les propriétés uniques de l'eau, la protégeant de toute influence humaine ou environnementale. L'eau contient précisément les minéraux et les sels dont les humains, les animaux et la nature ont besoin pour être en pleine forme. Et l'explication se trouve dans la roche. Car elle regorge de minéraux naturels qui se sont formés il y a 500 millions d'années, lorsque le fond de l'océan a été forcé vers le haut pour créer notre chaîne de montagnes nordique.`,
            part4: `Des histoires sur cette eau ont été transmises de génération en génération. Nos propres ancêtres élevaient ici des chevaux de travail. Forts, robustes et en bonne santé grâce à l'eau, disaient-ils. C'est ici, sur les rives du lac Vojmsjön à Vilhelmina, que l'une des plus anciennes traces d'existence humaine dans le nord de la Suède a été découverte : un foyer vieux de 8 000 ans. Lorsque la glace continentale s'est retirée, les gens se sont installés au bord de l'eau, ont construit un foyer et ont préparé des repas à base de poisson ou d'élan. Il est fort probable que l'eau cristalline ait eu pour eux un attrait particulier. Au cours des années 1700, les Samis de la région ont nommé le lac Vojmsjön "Hjärtsjön" ou "lac du cœur" en français, car on disait que son eau donnait aux gens un cœur fort et sain.`,
            part5: `Compte tenu d'un tel héritage, nous ressentons une immense gratitude et révérence pour cette eau et ses incroyables propriétés vivifiantes. C'est pourquoi nous la traitons avec le plus grand soin. Nous la puisons directement à la source, dans de belles bouteilles – sans intermédiaires, traitements ou additifs – et offrons ce produit exclusif dans le monde entier. Pour que vous aussi puissiez savourer le goût de JAURE.`,
        },
        timeline: {
            1: {
                title: "Substrat rocheux ancien",
                text: `
                Un solide substrat rocheux de granite formé il y a 2 millions d'années.
                `,
            },
            2: {
                title: "Source naturelle",
                text: `
                À 160 mètres de profondeur de ce substrat rocheux, une source naturelle s'est formée.
                Une veine gargouillante de l’eau la plus fraîche, la plus rafraîchissante et la plus claire. Jauré. 
                `,
            },
            3: {
                title: "L'eau la plus purer",
                text: `
                L'intérieur massif, fermé et solide du substrat rocheux préserve la pureté unique de l'eau, la gardant totalement intacte par les humains et l'environnement. 
                `,
            },
            4: {
                title: "Mis en bouteille à la source",
                text: `
                Nous traitons cette eau spéciale avec gratitude et respect.
                L’eau est mise en bouteille directement à la source, sans intermédiaires, transformations ni additifs. 
                C'est ainsi que naît Jaure.
                `,
            },
            5: {
                title: "Servi à votre table",
                text: `
                Une quantité exclusive de Jaure est expédiée partout dans le monde, pour finalement être servie à votre table.
                Pour que vous aussi puissiez profiter du plaisir de l’eau la plus pure.
                `,
            },
        },
    },
    about: {
        section1: {
            header: "Qualité et fierté",
            text: `Fondée en 2021, Jaure s'engage à exporter une eau de qualité supérieure aux quatre coins du monde, ravissant les consommateurs avisés, conscients de la valeur des produits de qualité et du respect de l'environnement. Notre eau, issue de notre propre source située dans le nord du Royaume de Suède, offre une expérience inédite et pure. Nos valeurs fondamentales se reflètent dans chaque aspect de notre entreprise et de notre gamme de produits. La qualité, la durabilité, le respect de l'environnement, la responsabilité sociale et la traçabilité sont les pierres angulaires de notre activité.`,
        },
        section2: {
            header: "",
            text: `Jaure est dirigée par l'entreprise familiale Katarina Gården. Notre histoire familiale est riche en récits transmis de génération en génération, témoignant de la pureté exceptionnelle de notre source et de son importance pour les personnes et l'environnement. Cependant, au-delà de ces récits, notre garantie repose sur des tests scientifiques rigoureux qui prouvent la qualité et la pureté de notre eau.
            Nos analyses techniques attestent de la pureté exceptionnelle de notre eau, confirmant qu'elle est exempte de contaminants et riche en minéraux naturels bénéfiques. Ces résultats scientifiques, combinés à notre engagement passionné et respectueux envers notre source, assurent à nos consommateurs une eau pure de la plus haute qualité.
            Les récits de notre famille ajoutent une dimension personnelle et chaleureuse à notre histoire, renforçant notre lien avec la nature et notre engagement envers l'excellence..`,
        },
        section3: {
            header: "La durabilité à chaque étape",
            text: ` Jaure travaille uniquement de manière durable et adaptée au climat – cela s'applique à tout, depuis nos opérations d'usine et notre personnel jusqu'à la manipulation des produits,
            transport et livraison. Nous pensons que l'eau propre est une ressource stratégique
            pour le développement sociétal, et nous savons qu’il est nécessaire que nos écosystèmes
            fonction, pour la production d’énergie, pour les secteurs verts et pour les industries et
            ménages. Notre objectif est que les générations futures comprennent et valorisent également
            l'importance de l'eau – pour eux-mêmes, pour notre environnement et pour notre avenir.`,
        },
    },
    story: {
        hero: {
            header: "Origine",
        },
        section1: {
            text: `Sur une colline entourée par les forêts verdoyantes du nord de la Suède,
            une source jaillit de l’ancien substrat rocheux – la source de la forme d’eau la plus pure.
            Depuis des milliers d'années, les humains, les animaux et la nature ont acquis leur
            vitalité de cette veine d’eau cristalline au doux gargouillis. Jauré.`,
        },
        section2: {
            text: `L'héritage
            de cette eau, de cet endroit même, a été transmise de génération en
            génération. C'est un héritage accompagné de gratitude et de respect pour le calme,
            la fraîcheur et la clart l'eau et ses fantastiques propriétés vivifiantes.`,
        },
        section3: {
            text: `L'eau fait partie de tout ici. En été, la forêt est épaisse et luxuriante, et les branches s'inclinent sous les lichens, signe d'un air pur et riche en oxygène. Des fleurs et des herbes couvrent les flancs des collines, leurs graines se répandant au loin via les ruisseaux et les ruisseaux. En hiver, le gargouillis de l’eau se fait entendre sous l’épaisse couche de neige.`,
        },
        section4: {
            text: `Dans les environs, il existe des signes évidents d'habitations remontant à l'âge de pierre. On pense que les sources naturelles ont attiré les gens vers cet endroit – avec une eau si claire que les pierres au fond semblent être à portée de main.`,
        },
        section5: {
            text: `La source Jaure jaillit de sa source, à 160 mètres de profondeur, dans un substrat granitique vieux de deux milliards d'années. Au cœur de la montagne, un système naturellement fermé retient la pureté unique de l'eau, la protégeant de tout impact humain ou environnemental. L'eau est puisée dans des bouteilles en verre recyclables directement à la source, et des quantités limitées exclusives sont ensuite livrées dans le monde entier. Vous apporter le plaisir de l'Eau la plus pure.`,
        },
    },
    products: {
        hero: {
            header: "Produits de Jaure",
            subheader: `Depuis les profondeurs de la montagne, dans un système naturellement fermé qui conserve la pureté unique de l'eau, la protégeant de tout impact humain ou environnemental.`,
        },
        signature: {
            header: "Verre Signature",
            description: `Une bouteille en verre de 250 ml confortable à tenir et qui se glisse parfaitement dans le sac à main ou la valise. Pour une hydratation exceptionnelle en déplacement.`,
            size: "250ml",
        },
        grande: {
            header: "Verre d'Excellence",
            description: `Puisée dans des bouteilles en verre recyclables et exclusives directement à la source, vous apportant le plaisir de l'eau la plus pure. Parfait pour une cuisine raffinée et des événements exclusifs.`,
            size: "700ml",
        },
        aviator: {
            header: "Coffret Aviateur",
            description: `La plus pure des eaux. Emballé à la source à partir d’un substrat rocheux ancien.
            Parfaitement dimensionné pour le yacht ou le jet privé.`,
            size: "3L / 5L",
        },
        minerals: {
            header: "Caractéristiques minérales exceptionnelles",
            row1: {
                header: "50 mg/l",
                subheader: "Calcium",
            },
            row2: {
                header: "4,4 mg/l",
                subheader: "Magnésium",
            },
            row3: {
                header: "4.9 mg/l",
                subheader: "Sodium",
            },
            row4: {
                header: "7,9",
                subheader: "PH",
            },
            row5: {
                header: "1,0 mg/l",
                subheader: "Potassium",
            },
            row6: {
                header: "<1,0 mg/l",
                subheader: "Chlorures",
            },
        },
    },
    contact: {
        hero: {
            header: "Nous contacter",
        },
        location: {
            1: {
                header: "Bureau principal",
            },
            2: {
                header: "Jaure Showroom Paris",
            },
        },
    },
};
