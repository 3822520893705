<template>
    <div id="autoVideo" class="auto-video" v-bind:class="{ 'show': show }">
        <video id="promoVideo" width="300" height="200"
               v-play="playing"
               @ended="onVideoEnd"
               @click="togglePlay">
            <source src="@/assets/videos/promo_c.mp4" type="video/mp4" />
        </video>
        <div id="buttons" v-bind:class="{ 'playing': playing, 'show': showButtonOverride }">
            <sp-button
                id="playButton"
                v-sp-ripple
                class="sp-button"
                @click="togglePlay"
            >
                <i class="mdi mdi-replay" v-bind:class="{ 'show': showReplay }"></i>
                <i class="mdi mdi-pause" v-bind:class="{ 'show': !showReplay & playing }"></i>
                <i class="mdi mdi-play" v-bind:class="{ 'show': !showReplay & !playing }"></i>
            </sp-button>
            <sp-button id="closeButton"
                       v-sp-ripple
                       class="sp-button" @click="hide"
            >
                <!-- <i class="mdi mdi-close"></i> -->
                To Jaure.se
            </sp-button>
        </div>
    </div>
</template>

<script>
    export default {
        directives: {
            play: {
                bind(el, binding, vnode) {
                    el.addEventListener("playing", () => {
                        vnode.context[binding.expression] = !el.paused;
                    });
                    el.addEventListener("pause", () => {
                        vnode.context[binding.expression] = !el.paused;
                    });
                    vnode.context[binding.expression] = !el.paused;
                },
                update(el, binding) {
                    if (el.paused) {
                        if (binding.value) {
                            el.play();
                        }
                    } else if (!binding.value) {
                        el.pause();
                    }
                },
            },
        },
        props: ["show"],
        data() {
            return {
                showButton: true,
                showReplay: false,
                playing: false,
                mouseEvent: null,
                firstPlay: true,
            };
        },
        computed: {
            showButtonOverride() {
                if (!this.playing) return true;
                return this.showButton;
            },
            paused() {
                return !this.playing;
            },
        },
        mounted() {
            this.mouseEvent = null;


            // this.$el.addEventListener("mouseleave", () => {
            //     clearTimeout(this.timer);
            // });
        },
        methods: {
            togglePlay() {
                if (this.firstPlay) {
                    this.fullscreen();
                }
                this.showReplay = false;

                this.playing = !this.playing;
                if (this.playing) {
                    this.mouseTimer();
                    this.$el.addEventListener("mousemove", () => {
                        this.mouseTimer();
                    });
                }
                this.firstPlay = false;
            },
            onVideoEnd() {
                this.showReplay = true;
                this.showButton = true;
            },
            hide() {
                this.exitFullscreen();
                this.playing = false;
                this.$emit("close", null);
            },
            mouseTimer() {
                this.showButton = true;
                clearTimeout(this.mouseEvent);
                this.mouseEvent = setTimeout(() => {
                    this.showButton = false;
                }, 600);
            },
            fullscreen() {
                const elem = document.getElementById("autoVideo");
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                    elem.msRequestFullscreen();
                }
                this.showButton = false;
            },
            exitFullscreen() {
                // const elem = document.getElementById("autoVideo");
                const isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null)
                    || (document.webkitFullscreenElement && document.webkitFullscreenElement !== null)
                    || (document.mozFullScreenElement && document.mozFullScreenElement !== null)
                    || (document.msFullscreenElement && document.msFullscreenElement !== null);

                if (!isInFullScreen) {
                    return;
                }
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";

    .auto-video {
        display: flex;
        pointer-events: none;
        opacity: 0;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,.8);
        z-index: 101;
        transition: .2s;

        &.show {
            opacity: 1;
            pointer-events: all;
        }

        video {
            cursor: default;
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: absolute;

            source { background: red; }
        }

        #buttons {
            position: absolute;
            display: flex;
            flex-direction: column;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-family: "Montserrat";
            opacity: 0;
            transition: .8s;

            &.show {
                opacity: 1;
                transition: .1s;
            }

            &.playing #playButton {
                color: $color100;
                background: rgba(0, 0, 0, 0.2);
            }

            // &.playing #closeButton {
            //     margin-bottom: -50%;
            //     opacity: 0;
            // }

            #playButton {
                font-size: 2rem;
                padding: 15px 20px;
                background: rgba(0, 0, 0, 0.3);
                color: $color100;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;

                .mdi {
                    opacity: 0;
                    transition: .2s;

                    &.show {
                        opacity: 1;
                    }
                }
                .mdi-close {
                    position: absolute;
                }
                .mdi-play, .mdi-pause {
                    position: absolute;
                }
            }
            #closeButton {
                margin-top: 20px;
                background: rgba(0, 0, 0, 0.3);
                color: $color100;
                font-weight: 500;
                z-index: 999;
                padding: 13px 22px;

                &.playing {
                    background: green;
                }
            }
        }

    }
</style>
