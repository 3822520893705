<template>
    <div class="page max-width anim-fadeIn">
        <div class="page-header" >
            <h1 class="anim-fadeIn" v-rellax="{speed: -2, }">{{$t('products.hero.header')}}</h1>
            <p class="anim-fadeIn" v-rellax="{speed: -2, }">
                {{$t('products.hero.subheader')}}
            </p>
        </div>
        <div id="product_bottle" class="product anim-fadeIn">
            <div class="product-img anim-fadeIn">
                <!-- <async-img :url="require('@/assets/images/products/bottle_4.png')"/> -->
                <!-- <async-img :url="require('@/assets/images/products/v2/swift.png')"/> -->
                <async-img :url="require('@/assets/images/products/v3/signature_transparent.png')"/>
            </div>
            <h1 class="anim-fadeIn">{{$t('products.signature.header')}}</h1>
            <p class="anim-fadeIn">
                {{$t('products.signature.description')}}

            </p>
            <h4 class="anim-fadeIn">{{$t('products.signature.size')}}</h4>
        </div>
        <div id="product_bottle" class="product anim-fadeIn">
            <div class="product-img anim-fadeIn">
                <!-- <async-img :url="require('@/assets/images/products/bottle_4.png')"/> -->
                <!-- <async-img :url="require('@/assets/images/products/v2/grande.png')"/> -->
                <async-img :url="require('@/assets/images/products/v3/grande_transparent.png')"/>
            </div>
            <h1 class="anim-fadeIn">{{$t('products.grande.header')}}</h1>
            <p class="anim-fadeIn">
                {{$t('products.grande.description')}}
            </p>
            <h4 class="anim-fadeIn">{{$t('products.grande.size')}}</h4>
        </div>
        <div id="product_box" class="product anim-fadeIn">
            <div class="product-img anim-fadeIn">
                <async-img :url="require('@/assets/images/products/bib.png')"/>
            </div>
            <h1 class="anim-fadeIn">{{$t('products.aviator.header')}}</h1>
            <p class="anim-fadeIn">
                {{$t('products.aviator.description')}}
            </p>
            <h4 class="anim-fadeIn">{{$t('products.aviator.size')}}</h4>
        </div>
        <div id="minerals" class="">
            <h2 class="anim-fadeIn">{{$t('products.minerals.header')}}</h2>
            <div id="wrapper_stats" class="anim-fadeIn">
                <div class="stat-item">
                    <h4>{{$t('products.minerals.row1.header')}}</h4>
                    <p>{{$t('products.minerals.row1.subheader')}}</p>
                </div>
                <div class="stat-item">
                    <h4>{{$t('products.minerals.row2.header')}}</h4>
                    <p>{{$t('products.minerals.row2.subheader')}}</p>
                </div>
                <div class="stat-item">
                    <h4>{{$t('products.minerals.row3.header')}}</h4>
                    <p>{{$t('products.minerals.row3.subheader')}}</p>
                </div>
                <div class="stat-item">
                    <h4>{{$t('products.minerals.row4.header')}}</h4>
                    <p>{{$t('products.minerals.row4.subheader')}}</p>
                </div>
                <div class="stat-item">
                    <h4>{{$t('products.minerals.row5.header')}}</h4>
                    <p>{{$t('products.minerals.row5.subheader')}}</p>
                </div>
                <div class="stat-item">
                    <h4>{{$t('products.minerals.row6.header')}}</h4>
                    <p>{{$t('products.minerals.row6.subheader')}}</p>
                </div>
            </div>

        </div>
        <div id="spacer"></div>

    </div>
</template>

<script>
    // components
    import AsyncImage from "../components/AsyncImage.vue";

    export default {
        components: {
            "async-img": AsyncImage,
        },
        props: ["images"],
        mounted() {
            // remove cuz cookies
            // this.$gtag.pageview({ page_title: "track-Products" });
        },
    };
</script>


<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";

    .page-header {
        p {
            text-align: center;
            opacity: .9;
            max-width: 680px;
        }
    }

    .product {
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto auto auto;
        position: relative;
        margin: 40px 0 0 0;
        padding: 0 0 50px 0;

        @media only screen and (max-width: $medium-trigger-width) {
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr;

            .product-img {
                grid-column: 1 !important;
                grid-row: 1 !important;
                margin: 0 0px 50px 0 !important;
                width: 100% !important;
            }


            &#product_bottle .product-img {
                height: 220px;
                width: 100% !important;
                margin: 0 auto 50px auto !important;
            }

            &#product_box .product-img {
                height: 220px;
                width: 100% !important;
                margin: 0 auto 50px auto !important;
            }
        }
        .product-img {
            height: 220px;

            img {
                object-fit: contain !important;
            }
        }

        &::after {
            content: "";
            display: flex;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            height: 1px;
            background: rgba(white, .1);
        }

        .product-img {
            grid-column: 1;
            grid-row: span 4;
            animation-duration: 2.5s !important;
            animation-delay: .2s !important;
            width: 350px;
            border-radius: 6px;
            overflow: hidden;
            margin: 0 30px 0 0;
            min-height: 200px;
            //background: $colorPurple;

            .async-image {
                width: 100%;
                height: 100%;
                vertical-align: middle;
                object-fit: cover;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        h1 {
            font-size: 1.2rem;
            color: white;
            animation-duration: 2.5s !important;
            animation-delay: .2s !important;
        }
        p {
            animation-duration: 2s !important;
            animation-delay: .5s !important;
            color: rgba(white, .8);
            font-size: .9rem !important;
            text-align: start;
            margin: 0 0 40px 0;

            &:last-of-type {
                margin: 0 0 10px 0;
            }
        }
        h4 {
            color: rgba(white, .9);
            animation-duration: 2.5s !important;
            animation-delay: .8s !important;
        }
    }

    #minerals {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $max-width;
        margin: 40px auto 0 auto;
        padding: 30px;
        border-radius: 6px;
        color: white;
        animation-duration: 1.2s !important;
        animation-delay: .5s !important;

        @media only screen and (max-width: $medium-trigger-width) {
            padding: 0;
            margin: 80px 0 0 0;
            h2 {
                margin: 0 0 30px 0 !important;
            }
        }

        h2 {
            font-size: 1.6rem;
            animation-duration: 2.5s !important;
            animation-delay: .5s !important;
            margin: 0 auto 30px auto;
        }
        #wrapper_stats {
            animation-duration: 2.5s !important;
            animation-delay: .8s !important;
            display: flex;
            gap: 20px;
            flex-wrap: wrap;

            .stat-item {
                flex: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media only screen and (max-width: $medium-trigger-width) {
                    align-items: flex-start;
                }

                h4 {
                    font-family: "Montserrat", sans-serif !important;
                }
            }
        }
        p {
            animation-duration: 2.5s !important;
            animation-delay: .8s !important;
            color: rgba(white, .8);
            font-size: .9rem !important;
            text-align: start;
            margin: 0 0 40px 0;
            span {
                color: white !important;
                font-weight: 500;
                font-size: 1.2rem;
                letter-spacing: .05rem;
            }
            &:last-of-type {
                margin: 0 0 10px 0;
            }
        }
    }

    #spacer {
        height: 100px;
    }
</style>
