<template>
    <div class="page max-width anim-fadeIn">
        <!-- <div class="page-header">
            <h1 class="anim-fadeIn" v-rellax="{speed: -2, }">The Story of Jaure</h1>

        </div> -->
        <div id="wrapper" class="anim-fadeIn">
            <async-img :url="require('@/assets/images/astro_v10/forest2.jpg')"/>
        </div>
        <div id="section_text" class="">
            <h1 class="anim-fadeIn">{{$t('story.hero.header')}}</h1>
            <p class="anim-fadeIn">
                {{$t('story.section1.text')}}
            </p>
            <p class="anim-fadeIn">
                {{$t('story.section2.text')}}
            </p>
            <p class="anim-fadeIn">
                {{$t('story.section3.text')}}
            </p>
            <p class="anim-fadeIn">
                {{$t('story.section4.text')}}
            </p>
            <p class="anim-fadeIn">
                {{$t('story.section5.text')}}
            </p>
        </div>
    </div>
</template>

<script>
    // components
    import AsyncImage from "../components/AsyncImage.vue";

    export default {
        components: {
            "async-img": AsyncImage,
        },
        props: ["images"],
        mounted() {
            // remove cuz cookies
            // this.$gtag.pageview({ page_title: "track-Story" });
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";

    .page {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
    }

    #wrapper {
        margin: 0 0 50px 0;
        width: 100%;
        height: 500px;
        overflow: hidden;
        border-radius: 6px;
        animation-duration: 1.5s !important;
        animation-delay: 0s !important;

        .async-image {
            width: 100%;
            height: 100%;
            vertical-align: middle;
            vertical-align: middle;
            object-fit: cover;
        }
    }
    h1 {
        font-size: 1.6rem;
        color: white;
        animation-duration: 2.5s !important;
        animation-delay: .2s !important;
    }
    h2 {
        font-size: 1.2rem;
        animation-duration: 2.5s !important;
        animation-delay: .2s !important;
    }
    p {
        animation-duration: 2s !important;
        animation-delay: .5s !important;
        color: rgba(white, .8);
        font-size: .9rem !important;
        text-align: start;
        margin: 0 0 40px 0;

        &:last-of-type {
            margin: 0 0 10px 0;
        }
    }
    #section_text {
        position: relative;
        display: flex;
        flex-direction: column;
        //gap: 30px;
        width: 100%;
        max-width: $max-width;
        margin: 20px auto 50px auto;
        //padding: 30px;
        border-radius: 6px;
        color: white;
        animation-duration: 1.2s !important;
        animation-delay: .5s !important;
    }
</style>
