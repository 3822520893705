export default {
    namespaced: true,
    state: {
        modalEnabledId: null,
        overlayEnabled: false,
        mobileMenuEnabled: false,
        toolbarOffset: 64,
        toolbarColors: ["white", "#707070"],
    },
    getters: {
        modalEnabledId: state => state.modalEnabledId,
        overlayEnabled: state => state.overlayEnabled,
        mobileMenuEnabled: state => state.mobileMenuEnabled,
        toolbarOffset: state => state.toolbarOffset,
        toolbarColors: state => state.toolbarColors,
    },
    actions: {
        setModalEnabledId({ commit }, modalId) {
            commit("setModalEnabledId", modalId);
            commit("setOverlayEnabled", modalId !== null);
        },
        setOverlayEnabled({ commit }, enabled) {
            commit("setOverlayEnabled", enabled);
            if (!enabled) {
                commit("setModalEnabledId", null);
                commit("setMobileMenuEnabled", false);
            }
        },
        setMobileMenuEnabled({ commit }, enabled) {
            commit("setMobileMenuEnabled", enabled);
            commit("setOverlayEnabled", enabled);
        },
        setToolbarOffset({ commit }, offset) {
            commit("setToolbarOffset", offset);
        },
        setToolbarColors({ commit }, colors) {
            commit("setToolbarColors", colors);
        },
    },
    mutations: {
        setModalEnabledId(state, modalId) {
            state.modalEnabledId = modalId;
        },
        setOverlayEnabled(state, enabled) {
            state.overlayEnabled = enabled;
        },
        setMobileMenuEnabled(state, enabled) {
            state.mobileMenuEnabled = enabled;
        },
        setToolbarOffset(state, offset) {
            state.toolbarOffset = offset;
        },
        setToolbarColors(state, colors) {
            state.toolbarColors = colors;
        },
    },
};
