<template>
    <sp-dropdown ref="languageDropdown" id="language_dropdown" :align="align" :expand="expand">
        <div class="trigger">
            <img  v-for="(langauge, index) in languages" :key="'lan-'+index"
                  :src="require('@/assets/images/flags/' + langauge + '.svg')"
                  :class="{'selected': langauge == activeLanguage}"
            >
        </div>
        <template v-slot:content>
            <div class="content">
                <img  v-for="(langauge, index) in languages" :key="'lan-sel-'+index"
                      :src="require('@/assets/images/flags/' + langauge + '.svg')"
                      :class="{'selected': langauge == activeLanguage}"
                      @click="setLanguage(langauge)"
                >
            </div>
        </template>
    </sp-dropdown>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";

    export default {
        props: {
            align: {
                type: String,
                default: "bottom-right",
            },
            expand: {
                type: String,
                default: "bottom-left",
            },
        },
        data() {
            return {
                languages: [
                    "en", "ar", "fr",
                ],
            };
        },
        computed: {
            ...mapGetters(["isMobile"]),
            activeLanguage() {
                const { lang } = this.$route.params;
                const htmlDoc = document.querySelector("html");
                if (lang === "ar") {
                    htmlDoc.dir = "rtl";
                    htmlDoc.lang = lang;
                } else {
                    htmlDoc.dir = "ltr";
                    htmlDoc.lang = lang;
                }
                return lang;
            },
        },
        methods: {
            ...mapActions("app", [
                "setMobileMenuEnabled",
            ]),
            setLanguage(lan) {
                this.$router.replace({
                    name: this.$route.name,
                    params: {
                        lang: lan,
                    },
                });
                this.$forceUpdate();

                // workaround to close menu on mobile & toggle language dropdown
                // TODO: should be fixed in spatial library
                if (this.$store.state.isMobile) {
                    this.setMobileMenuEnabled(false);
                }
                // toggle Dropdown
                this.$refs.languageDropdown.toggle();
            },
        },
    };
</script>


<style lang="scss" scoped>
    @import "@hagbardpaulson/spatial-beta/dist/base/helpers.scss";
    @import "@hagbardpaulson/spatial-beta/dist/base/shadows.scss";
    @import "@/assets/scss/variables.scss";

    .sp-dropdown {
        margin: auto 0 auto 20px;

        /deep/ .sp-dropdown-null {
            transform: scale(1) translateX(10px) !important;
        }
        .trigger
        {
            height: 18px;
            width: 24px;
            border-radius: 4px;
            border: none;
            outline: none;
            cursor: pointer;

            img {
                position: absolute;
                border-radius: 2px;
                overflow: hidden;
                opacity: 0;
                transition: .2s;
                height: 18px !important;

                &.selected {
                    opacity: 1;
                }
            }
        }
        /deep/ .sp-dropdown-content {
            margin-top: 5px;
            border-radius: 6px;
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-rows: auto;
            padding: 5px;
            @include sp-z(2);
            @include colorBackgroundFaded();

            .language {
                min-width: 100px;
                display: flex;
                img {
                    flex: auto;
                }

            }
        }
    }

</style>
