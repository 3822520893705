import BadRequestPage from "@/areas/shared/views/status/400BadRequest.vue";
import UnauthorizedPage from "@/areas/shared/views/status/401Unauthorized.vue";
import NotFoundPage from "@/areas/shared/views/status/404NotFound.vue";
import TwoFactorPage from "@/areas/shared/views/status/403TwoFactor.vue";
import ForbiddenPage from "@/areas/shared/views/status/403Forbidden.vue";

const routes = [
    {
        path: "",
        name: "BadRequest",
        meta: {},
        component: BadRequestPage,
    },
    {
        path: "/unauthorized",
        name: "Unauthorized",
        meta: {},
        component: UnauthorizedPage,
    },
    {
        path: "**",
        name: "404",
        meta: {},
        // component: require("@/views/status/404.vue").default
        component: NotFoundPage,
    },
    {
        path: "**",
        name: "403-twofactor",
        meta: {},
        // component: require("@/views/status/404.vue").default
        component: TwoFactorPage,
    },
    {
        path: "**",
        name: "403",
        meta: {},
        // component: require("@/views/status/404.vue").default
        component: ForbiddenPage,
    },
];

export default routes.map((route) => {
    const defaultMeta = {
        layout: "layout-empty",
        isPublic: true,
    };
    const meta = { ...route.meta, ...defaultMeta };
    return { ...route, meta };
});
