/* eslint-disable import/no-cycle */

import Vue from "vue";
import Vuex from "vuex";

import appModule from "./modules/app";


Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    strict: process.env.NODE_ENV !== "production",
    state: {
        isLoading: false,
        isMobile: false,
        mobileMenuEnabled: false,
        overlayEnabled: true,
        breadcrumbs: [],
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        setIsMobile(state, isMobile) {
            state.isMobile = isMobile;
        },
        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
    },
    getters: {
        isMobile: state => state.isMobile,
    },
    modules: {
        // modules
        app: appModule,
    },
});
