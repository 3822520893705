export default {
    greeting: "Welcome to Your Vue.js App",
    start: {
        hero: {
            header: "Det renaste vattnet",
            subheader: "Bottled at source from ancient bedrock in northern scandinavia",
        },
        timeline: {
            1: {
                title: "Ancient bedrock",
                text: `
                The solid bedrock of granite, that is the foundation of 
                life for the people of northern Sweden, was formed two million years ago.
                `,
            },
            2: {
                title: "Natural spring",
                text: `
                160 metres down in the depths of this bedrock, a natural spring was formed. 
                A gurgling vein of the coolest, most refreshing and clearest water. Jaure. 
                `,
            },
            3: {
                title: "The purest of water",
                text: `
                The bedrock's massive, enclosed and steadfast interior safeguards the unique purity of the water - 
                keeping it completely untouched by humans and the surroundings. 
                `,
            },
            4: {
                title: "Bottled at source",
                text: `
                We handle this special water with gratitude and reverence. 
                It is bottled directly at the source of the spring, free of intermediaries, 
                processing or additives. This is how Jaure is born. 
                `,
            },
            5: {
                title: "Served at your table",
                text: `
                An exclusive quantity of Jaure is shipped all over the world, 
                eventually being served at your table. 
                So that you too can enjoy the pleasure of the purest of water.
                `,
            },
        },
    },
    about: {},
    story: {},
    products: {},
    contact: {},
};
