/* eslint-disable max-len */
export default {
    greeting: "Welcome to Jaure",
    menu: {
        jaure: "JAURE",
        about: "About",
        story: "The Story",
        products: "Products",
        contact: "Contact",
    },
    start: {
        hero: {
            header: "Our Legacy",
            subheader: "Bottled at source from ancient bedrock, located in the northern parts of the Kingdom of Sweden",
        },
        story: {
            header: "Let us tell you our story",
            part1: `JAURE is a Swedish, family-run business that’s

                        passionate about quality, sustainability and so-
                        cial responsibility. We want to share the finest

                        of gifts with the world – crystal-clear, soft and
                        fantastically good water, bottled at source on
                        our land.`,
            part2: `Six generations of our family have owned and
                        used this very land. Water is part of everything
                        here, and we’ve grown up with the tales of its

                        life-giving powers. In the summertime, the fo-
                        rest is thick and moist, and the branches bow

                        low with lichen, a sign of clean oxygen-rich air.

                        The hills are covered with wild flowers and her-
                        bs. And during winter, the water gurgles under a

                        thick layer of snow. Our area is famous for being
                        first-rate hunting grounds too, a place that has
                        attracted moose, bears and other wild game
                        that thrive on the fresh grass and mineral-rich
                        water. While we have always taken our JAURE

                        water for granted, water is an increasingly scar-
                        ce resource in the world.`,
            part3: `The JAURE spring rises from 160 metres down in
                        the two-billion-year-old granite bedrock. There

                        in the depths of the mountain, a naturally clo-
                        sed system retains the unique properties of the

                        water, protecting it from any human or environ-
                        mental impact. The water possesses precisely

                        the minerals and salts that humans, animals and

                        nature need to feel their very best. And the expla-
                        nation is inside the rock. For therein lies an abun-
                        dance of natural minerals that came about 500

                        million years ago, when the ocean floor was forced
                        upwards and created our Nordic mountain chain.`,
            part4: `Tales about this water have been passed down

                        from generation to generation. Our own forefath-
                        ers raised working horses here. Strong, steadfast

                        and healthy thanks to the water, so they used to
                        say. Right here, on the shores of Vojmsjön lake
                        in Vilhelmina, one of the oldest traces of human
                        existence in northern Sweden has been found. An

                        8,000-year-old fireplace. When the inland ice re-
                        treated, people settled at the water’s edge, built a

                        fire pit and cooked meals made of fish or moo-
                        se. In all probability, the crystal-clear water had

                        its own special appeal to them. During the 1700s
                        the Sami people of the area named Vojmsjön lake
                        “Hjärtsjön” or “Heart lake” in English, as its water
                        was said to give people a strong and healthy heart.`,
            part5: `Given such a legacy, we feel enormous gratitu-
                        de and reverence for this water and its fantastic

                        life-giving properties. That’s why we treat it with
                        the utmost of care. We tap it directly at the source
                        straight into beautiful bottles – no intermediaries,
                        processing or additives – and offer this exclusive

                        product all over the world. So that you too can en-
                        joy the taste of JAURE.`,
        },
        timeline: {
            1: {
                title: "Ancient bedrock",
                text: `
                The solid bedrock of granite, that is the foundation of 
                life for the people of northern Sweden, was formed two million years ago.
                `,
            },
            2: {
                title: "Natural spring",
                text: `
                160 metres down in the depths of this bedrock, a natural spring was formed. 
                A gurgling vein of the coolest, most refreshing and clearest water. Jaure. 
                `,
            },
            3: {
                title: "The purest of water",
                text: `
                The bedrock's massive, enclosed and steadfast interior safeguards the unique purity of the water - 
                keeping it completely untouched by humans and the surroundings. 
                `,
            },
            4: {
                title: "Bottled at source",
                text: `
                We handle this special water with gratitude and reverence. 
                It is bottled directly at the source of the spring, free of intermediaries, 
                processing or additives. This is how Jaure is born. 
                `,
            },
            5: {
                title: "Served at your table",
                text: `
                An exclusive quantity of Jaure is shipped all over the world, 
                eventually being served at your table. 
                So that you too can enjoy the pleasure of the purest of water.
                `,
            },
        },
    },
    about: {
        // hero: {
        //     header: "Our legacy, bottled at source from ancient bedrock",
        //     subheader: `Clean water is the foundation for all life on earth. Humankind needs it to
        //     produce food and energy, to remain healthy and to ensure sustainable
        //     development.`,
        // },
        section1: {
            header: "Quality and pride",
            text: `Jaure was founded in 2021 and exports quality water to a conscious market
            all over the world. Our water comes from its own spring from the northern parts of Kingdom of Sweden
            and brings a whole new experience to how pure water should taste. Our core
            values are reflected in everything we do and in our product range; quality,
            sustainability, an environmental mindset, social responsibility and traceability
            are obvious cornerstones for our activities.`,
        },
        section2: {
            header: "",
            text: `Jaure is run by the family-owned company KatarinaGården. Our family history
            is full of tales that have been handed down from generation to generation,
            tales that testify to the special purity of our spring water and its significance
            to people and the environment. These tales are your guarantee that we deliver
            pure water, treated with great love and reverence.`,
        },
        section3: {
            header: "Sustainability at every step",
            text: ` Jaure works solely in a sustainable and climate-adapted manner – this applies
            to everything from our factory operations and our personnel to product handling,
            transportation and delivery. We believe clean water is a strategic resource
            for societal development, and we know that it’s needed for our ecosystems to
            function, for energy production, for green sectors and for industries and
            households. Our goal is that future generations will also understand and value
            the significance of water – for themselves, for our environment and for our future.`,
        },
    },
    story: {
        hero: {
            header: "Origin",
        },
        section1: {
            text: `On a hillside enshrouded by the lush green forests of northern Sweden,
            a spring rises up through the ancient bedrock – the source of the purest form of water.
            For thousands of years, humans, animals and nature alike have gained their
            vitality from this gently gurgling vein of crystal-clear water. Jaure.`,
        },
        section2: {
            text: `The legacy
            of this water, from this very place, has been passed down from generation to
            generation. It is a legacy accompanied by gratitude and reverence for the cool, fresh, clear
            water and its fantastic life-giving properties.`,
        },
        section3: {
            text: `Water is part of everything here.
            In the summertime, the forest is thick and lush, and the
            branches bow low with lichen, a sign of clean air that is rich in oxygen. Flowers and herbs
            cover the hillsides, their seeds spreading afar via the brooks and streams. During winter, the
            gurgling of water can be heard from under the thick layer of snow.`,
        },
        section4: {
            text: `In the surrounding area,
            there are clear signs of settlements dating as far back as the Stone
            age. It is believed that the natural springs attracted people to the place – with water so clear
            that the stones deep down at the bottom appear to be within grasp.`,
        },
        section5: {
            text: `The Jaure spring
            bubbles up from its source, 160 metres down in the two-billion-year-old
            granite bedrock. There in the depths of the mountain, a naturally closed system retains the
            unique purity of the water, protecting it from any human or environmental impact. The
            water is tapped into recyclable glass bottles directly at the source, and exclusive limited
            amounts are then delivered around the world. Bringing you the pleasure of the purest of
            water.`,
        },
    },
    products: {
        hero: {
            header: "Products of Jaure",
            subheader: `From the depths of the mountain, in a naturally closed system that retains
            the unique purity of the water, protecting it from any human or environmental impact.`,
        },
        signature: {
            header: "Signature Glass",
            description: `A 250ml glass bottle that is comfortable to hold and fits perfectly in the handbag or suitcase.
            For exceptional hydration on the go.`,
            size: "250ml",
        },
        grande: {
            header: "Grande Glass",
            description: `Tapped into recyclable and exclusive glass bottles directly at the source,
            bringing you the pleasure of the purest of water. Perfect for fine dining and exclusive events.`,
            size: "700ml",
        },
        aviator: {
            header: "Aviator Box",
            description: `The purest of water. Packaged at source from ancient bedrock.
            Perfectly sized for the yacht or private jet.`,
            size: "3L / 5L",
        },
        minerals: {
            header: "Outstanding mineral characteristics",
            row1: {
                header: "50 mg/l",
                subheader: "Calcium",
            },
            row2: {
                header: "4,4 mg/l",
                subheader: "Magnesium",
            },
            row3: {
                header: "4.9 mg/l",
                subheader: "Natrium",
            },
            row4: {
                header: "7,9",
                subheader: "PH",
            },
            row5: {
                header: "1,0 mg/l",
                subheader: "Calium",
            },
            row6: {
                header: "<1,0 mg/l",
                subheader: "Chlorides",
            },
        },
    },
    contact: {
        hero: {
            header: "Get in touch",
        },
        location: {
            1: {
                header: "Main Office",
            },
            2: {
                header: "Jaure Showroom Paris",
            },
            3: {
                header: "Jaure Inc",
            },
        },
    },
};
