import en from "./locales/en";
import se from "./locales/se";
import ar from "./locales/ar";
import fr from "./locales/fr";

export default {
    en,
    se,
    ar,
    fr,
};
