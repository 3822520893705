
const cookieName = "cookieconsent";
const cookieAccepted = "cookieconsent_accepted";

class CookieConcent {
    static accept() {
        const date = new Date();
        // Cookie is valid 1 year: now + (days x hours x minutes x seconds x milliseconds)
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));

        document.cookie = `${cookieName}=${cookieAccepted};expires=${date.toUTCString()};path=/`;
        // console.log("cookieconsent accepted");

        return document.cookie;
    }

    static isAccepted() {
        // Get all cookies as string
        const decodedCookie = decodeURIComponent(document.cookie);
        // Separate cookies
        const cookies = decodedCookie.split(";");
        // console.log(cookies);
        for (let i = 0; i < cookies.length; i += 1) {
            const cookie = cookies[i].trim();
            // Check if cookie is present
            if (cookie === `${cookieName}=${cookieAccepted}`) {
                return true;
            }
        }

        return false;
    }
}
export default CookieConcent;
