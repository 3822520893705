<template>
    <div class="timeline" v-bind:class="{ 'rtl': $route.params.lang === 'ar' }">
        <div v-for="(item, index) in timelineData" :key="'timeline-'+index"
             class="timeline-item-wrapper"
             :class="'timeline-position-'+item.position"
        >
            <div class="timeline-center"></div>
            <div class="timeline-icon">
                <i :class="'mdi mdi-'+item.icon"></i>
            </div>
            <div class="timeline-item" v-sp-inview="'100px'">
                <div class="timeline-item-header">
                    <hr/>
                    <h1>{{item.header}}</h1>
                </div>

                <p>{{item.text}}</p>
            </div>
        </div>
        <!-- <div v-if="endData" class="timeline-end">
            <div class="timeline-icon">
                <i :class="'mdi mdi-'+endData.icon"></i>
            </div>
            <h1>{{endData.header}}</h1>
            <p>{{endData.text}}</p>
        </div> -->

    </div>
</template>

<script>
    export default {
        data() {
            return {
                timelineData: [],
            };
        },
        watch: {
            $route(to, from) {
                if (to.params.lang !== from.params.lang) {
                    // console.log("switch");
                    this.loadLocale();
                }
            },
        },
        created() {
            this.loadLocale();
        },
        methods: {
            loadLocale() {
                this.timelineData = [
                    {
                        position: "left",
                        icon: "waves-arrow-up",
                        header: this.$t("start.timeline.1.title"),
                        text: this.$t("start.timeline.1.text"),
                    },
                    {
                        position: "right",
                        icon: "water",
                        header: this.$t("start.timeline.2.title"),
                        text: this.$t("start.timeline.2.text"),
                    },
                    {
                        position: "left",
                        icon: "microscope",
                        header: this.$t("start.timeline.3.title"),
                        text: this.$t("start.timeline.3.text"),
                    },
                    {
                        position: "right",
                        icon: "water-pump",
                        header: this.$t("start.timeline.4.title"),
                        text: this.$t("start.timeline.4.text"),
                    },
                    {
                        position: "left",
                        icon: "bottle-wine",
                        header: this.$t("start.timeline.5.title"),
                        text: this.$t("start.timeline.5.text"),
                    },
                ];
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";

    .timeline {
        display: flex;
        flex-direction: column;
        position: relative;

        // .timeline-center {
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     width: 2px;
        //     background: gray;
        //     //@include colorGold;
        // }

        .timeline-item-wrapper {
            position: relative;
            width: 100%;

            @media only screen and (max-width: $medium-trigger-width) {
                //border:1px solid green;
                margin-left: 0% !important;
                margin-right: 0% !important;
                width: 100% !important;

                p {
                    max-width: 400px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .timeline-center {
                    left: 0;
                    right: initial;
                    transform: translateX(-50%);
                }
                .timeline-icon {
                    position: absolute;
                    transform: translateX(-50%);
                    left: 0% !important;
                }
                .timeline-item-header hr {
                    grid-column: 1 !important;
                    margin: auto 15px auto 20px;
                }

                // inview animation
                .timeline-item.before-enter {
                    opacity: 0;
                    transform: translateX(-10px) !important;

                }
                .timeline-item.enter {
                    opacity: 1;
                    transform: translateX(0px) !important;
                }
            }

            &.timeline-position-right {
                //border:1px solid green;
                margin-left: 50%;
                width: 50%;

                .timeline-center {
                    left: 0;
                    transform: translateX(-50%);
                }
                .timeline-icon {
                    position: absolute;
                    transform: translateX(-50%);
                }
                .timeline-item-header hr {
                    grid-column: 1 !important;
                    margin: auto 15px auto 20px;
                }

                // inview animation
                .timeline-item.before-enter {
                    opacity: 0;
                    transform: translateX(-10px);

                }
                .timeline-item.enter {
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
            &.timeline-position-left {
                //border:1px solid red;
                margin-right: 50%;
                width: 50%;

                .timeline-center {
                    right: 0;
                    transform: translateX(50%);
                }
                .timeline-icon {
                    position: absolute;
                    transform: translateX(-50%);
                    left: 100%;
                }
                .timeline-item-header hr {
                    grid-column: 3;
                    margin: auto 20px auto 15px;
                }

                // inview animation
                .timeline-item.before-enter {
                    opacity: 0;
                    transform: translateX(10px);

                }
                .timeline-item.enter {
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
            &:last-of-type {
                .timeline-center {
                    display: none;
                }
            }
            .timeline-center {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 2px;
                //background: rgba(0, 0, 0, .15);
                background: rgba(white, .2);
                top: 25px;
                bottom: 5px;
            }

            .timeline-item {
                display: flex;
                flex-direction: column;
                transition: 1s;

                .timeline-item-header {
                    display: grid;
                    grid-template-columns: 1fr auto 1fr;
                    grid-template-rows: 1fr;

                    h1 {
                        grid-column: 2;
                        grid-row: 1;
                        text-align: center;
                        font-size: 1.1rem;
                    }

                    hr {
                        grid-row: 1;
                        border: none;
                        border-top: 2px dashed rgba(white, .2);
                        height: 2px;
                        /* Set the hr color */
                        // color: rgba(0, 0, 0, .1); /* old IE */
                        // background-color: rgba(0, 0, 0, .05); /* Modern Browsers */
                        // background-image: linear-gradient(to right, rgba(0, 0, 0, .4) 33%, rgba(255,255,255,0) 0%);
                        // background-position: bottom;
                        // background-size: 6px 2px;
                        // background-repeat: repeat-x;
                    }
                }
                p {
                    padding: 0 30px;
                    margin-bottom: 100px;
                    text-align: center;
                }
            }
        }

        .timeline-end {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .timeline-icon {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            h1, p {
                text-align: center;
            }
            h1 {
                margin: 30px 0 0;
            }
            p {
                max-width: 500px;
            }
        }

        .timeline-icon {
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                color: white;
                font-size: 1.2rem;
            }
        }

        h1 {
            font-size: 1.2rem;
            color: white;
        }
        p {
            font-size: .9rem;
            color: rgba(255, 255, 255, .8);
        }
    }

    .timeline.rtl {
        display: flex;
        flex-direction: column;
        position: relative;

        // .timeline-center {
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     left: 50%;
        //     transform: translateX(-50%);
        //     width: 2px;
        //     background: gray;
        //     //@include colorGold;
        // }

        .timeline-item-wrapper {
            position: relative;
            width: 100%;

            @media only screen and (max-width: $medium-trigger-width) {
                //border:1px solid green;
                margin-left: 0% !important;
                margin-right: 0% !important;
                width: 100% !important;

                p {
                    max-width: 400px;
                    margin-left: auto;
                    margin-right: auto;
                }
                .timeline-center {
                    right: 0 !important;
                }
                .timeline-icon {
                    position: absolute;
                    transform: translateX(0);
                    right: -18px !important;
                }
                .timeline-item-header hr {
                    grid-column: 1;
                    margin: auto 15px auto 20px;
                }

                // inview animation
                .timeline-item.before-enter {
                    opacity: 0;
                    transform: translateX(-10px) !important;

                }
                .timeline-item.enter {
                    opacity: 1;
                    transform: translateX(0px) !important;
                }
            }

            &.timeline-position-right {
                margin-left: 50%;
                width: 50%;

                @media only screen and (max-width: $medium-trigger-width) {
                    .timeline-item-header hr {
                        grid-column: 1 !important;
                        margin: auto 15px auto 20px;
                    }
                }

                @media only screen and (min-width: $medium-trigger-width) {
                    .timeline-item-header hr {
                        grid-column: 3 !important;
                        margin: auto 15px auto 20px;
                    }
                }

                .timeline-center {
                    left: 0;
                    transform: translateX(-50%);
                }
                .timeline-icon {
                    position: absolute;
                    transform: translateX(-50%);
                    left: 0;
                }

                // inview animation
                .timeline-item.before-enter {
                    opacity: 0;
                    transform: translateX(-10px);

                }
                .timeline-item.enter {
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
            &.timeline-position-left {
                //border:1px solid red;
                margin-right: 50%;
                width: 50%;

                .timeline-center {
                    right: 0;
                    transform: translateX(50%);
                }
                .timeline-icon {
                    position: absolute;
                    transform: translateX(-50%);
                    left: 100%;
                }
                .timeline-item-header hr {
                    grid-column: 3;
                    margin: auto 20px auto 15px;
                }

                // inview animation
                .timeline-item.before-enter {
                    opacity: 0;
                    transform: translateX(10px);

                }
                .timeline-item.enter {
                    opacity: 1;
                    transform: translateX(0px);
                }
            }
            &:last-of-type {
                .timeline-center {
                    display: none;
                }
            }
            .timeline-center {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 2px;
                //background: rgba(0, 0, 0, .15);
                background: rgba(white, .2);
                top: 25px;
                bottom: 5px;
            }

            .timeline-item {
                display: flex;
                flex-direction: column;
                transition: 1s;

                .timeline-item-header {
                    display: grid;
                    grid-template-columns: 1fr auto 1fr;
                    grid-template-rows: 1fr;

                    h1 {
                        grid-column: 2;
                        grid-row: 1;
                        text-align: center;
                        font-size: 1.1rem;
                    }

                    hr {
                        grid-row: 1;
                        border: none;
                        border-top: 2px dashed rgba(white, .2);
                        height: 2px;
                        grid-column: 1;
                        /* Set the hr color */
                        // color: rgba(0, 0, 0, .1); /* old IE */
                        // background-color: rgba(0, 0, 0, .05); /* Modern Browsers */
                        // background-image: linear-gradient(to right, rgba(0, 0, 0, .4) 33%, rgba(255,255,255,0) 0%);
                        // background-position: bottom;
                        // background-size: 6px 2px;
                        // background-repeat: repeat-x;
                    }
                }
                p {
                    padding: 0 30px;
                    margin-bottom: 100px;
                    text-align: center;
                }
            }
        }

        .timeline-end {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .timeline-icon {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            h1, p {
                text-align: center;
            }
            h1 {
                margin: 30px 0 0;
            }
            p {
                max-width: 500px;
            }
        }

        .timeline-icon {
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                color: white;
                font-size: 1.2rem;
            }
        }

        h1 {
            font-size: 1.2rem;
            color: white;
        }
        p {
            font-size: .9rem;
            color: rgba(255, 255, 255, .8);
        }
    }
</style>
