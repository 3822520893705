<template>
    <div class="page-card-scroll-content anim-fadeIn">
        <slot></slot>
    </div>
</template>

<script>
    export default {

    };
</script>
