import Vue from "vue";
import Router from "vue-router";

import indexPage from "@/areas/shared/views/index.vue";

import v3Routes from "@/router/routes/v3";
import statusRoutes from "@/router/routes/status";
import i18n from "../plugins/i18n";


Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "index",
            component: indexPage,
            meta: {
                layout: "layout-empty",
                isPublic: true,
            },
        },
    ].concat(v3Routes, statusRoutes),
});


// use beforeEach route guard to set the language
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
    let finalNext = null;
    if (to.path === "/promo") {
        // return {
        //     name: "v3-jaure-about",
        //     query: {
        //         page: "1",
        //     },
        // };
        // return { path: "to.path", query: {}, hash: to.hash };
        // to.path = "en/promo";
        // if (to !== null) {
        finalNext = "/en/?promo=true";
        // finalNext = {
        //     ...to,
        //     path: "",
        //     query: {
        //         ...to.query,
        //         page: "1",
        //     },
        //     // params: { lang: "en" },
        // };
        // }
    }
    // use the language from the routing param or default language
    let language = to.params.lang;
    if (!language) {
        language = "en";
    }

    // set the current language for vuex-i18n. note that translation data
    // for the language might need to be loaded first
    i18n.locale = language;
    // console.log(i18n);
    if (finalNext) {
        next(finalNext);
    } else {
        next();
    }
});
// router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));
export default router;
